.site-header {
  background: #fff;
  border-bottom: 1px #e3e3e3 solid;
  box-shadow: 10px 18px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  @media (max-width: 640px) {

  }
  &.scrolled {

    
    top: 0;
    box-shadow: 0 2rem 2rem -2rem rgba(0, 0, 0, 0.1);

  }
  &.hide {
    opacity: 0;
    top: -110px;
   
  }
  &.active {
    @media (max-width: 650px) {
      height: 100vh;
      position:fixed;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .inner-wrap {
      position: relative;
      .main-product-menu {
        opacity: 1;
        display: block;
        height: fit-content;
      }
      .site-branding a.site-logo img {
        width: 190px;
    }
    }

    .main-product-menu {
      opacity: 1;
      display: block;
      height: auto;
    }
  }
  .inner-wrap {
    position: relative;
    @media (max-width: 1600px) {
      height: auto;
      max-width: 90%;
    }

    
    .navbar {
      position: relative;
      width: 100%;
      height: 135px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 1600px) {
        height: 122px;
      }
      @media (max-width: 700px) {
        height: 109px;
      }
      @media (max-width: 600px) {
        
        min-height: 90px;
      }

      .main-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 260px);
        padding-left: 40px;
        @media (max-width: 1600px) {
          padding-left: 0;
          width: calc(100% - 250px);
        }
        @media (max-width: 700px) {
          width: calc(100% - 210px);
        }
        @media (max-width: 600px) {
          width:65%;
        }
        @media (max-width: 460px) {
          width:69%;
        }

        .products-menu {
          @media (max-width: 820px) {
            display: none;
          }
          text-decoration: none !important;
          .title {
            display: flex;
            flex-direction: column;
            text-decoration: none !important;
            h3 {
              font-size: 28px;
              line-height: 1.2;
              font-style: normal;
              font-weight: $medium;
              color: #1a1f23;
              font-family: bitter, serif;
              font-weight: 700;
              font-style: normal;
              display: flex;
              width: 100%;
              justify-content: space-between;
              text-decoration: none !important;
              margin-bottom: 7px;
              margin-top: 12px;
              @media (max-width: 1600px) {
                font-size: 22px;
              }
              @media (max-width: 900px) {
                margin-bottom: 0;
              }
              @media (max-width: 600px) {
                font-size: 18px;
                position: relative;
                margin-top: 0;
              }
              &:after {
                margin-left: 15px;
                background-color: #1a1f23;
                border-radius: 50%;
                display: block;
                background-image: url(../img/ico_arrow_left.svg);
                content: " ";
                background-size: 12px 12px;
                background-position: center;
                background-repeat: no-repeat;
                height: 26px;
                margin-top: 7px;
                width: 26px;
                transform: rotate(90deg);
                @media (max-width: 1600px) {
                  height: 23px;
                  width: 23px;
                  margin-top: 0;
                }
                @media (max-width: 600px) {
                  background-size: 7px 7px;
                  height: 17px;
                  width: 17px;
                  position: absolute;
                  right: 0;
                }
              }
            }
            p {
              margin: 0;
              color:  #76797B;
              font-weight: 400;
              font-size: 14px;
              text-decoration: none !important;
              @media (max-width: 600px) {
                font-size: 11px;
              }
              @media (max-width: 460px) {
                display:none;
              }
            }
            &:hover {
              cursor: pointer;
              text-decoration: none !important;
              p,h3{
                text-decoration: none !important;
              }
            }
          }
        }
        .main-nav {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          ul.nav-list {
            display: flex;

            li {
              line-height: 0.9;
              display: flex;
              align-items: center;
              

              a {
                color: #76797B;
                font-weight: 400;
                font-size: 14px;
                padding: 0 8px;
                border-right: 1px solid  #76797B;
              }
              &:nth-child(2) {
                a {
                  border-right: none;
                }
              }
              &.login {
                a {
                  border-right: none;
                  font-weight: 600;
                  color: #1A1F23;
                }
              }
              i {
                font-size: 24px;
                color: #1a1f23;
                margin-left: 3px;
                position: relative;
                top: 1px;
              }

            }
          }

          .member_menu{
            display: flex;
            flex-direction: row;
            list-style: none;
            margin-left: 1rem;
            li{
              margin-left: 1rem;


              i {
                font-size: 20px;
                color: #334958;
                margin-left: 3px;
                position: relative;
                top: 4px;
              }

              span.account-dropdown-tigger {
                cursor: pointer;
                display: block;
                position: relative;
                &:active {
                  top: 1px;
                }
              }
              a.notification-link {
                position: relative;
                .counter {
                  line-height: 12px;
                  background: $green;
                  color: #fff;
                  border-radius: 50%;
                  overflow: hidden;
                  border: 2px solid #fff;
                  position: absolute;
                  top: -16px;
                  right: -12px;
                  font-size: 9px;
                  font-weight: 600;
                  text-align: center;
                  display: inline-block;
                  padding: 4px;
                  min-width: 24px;
                }
              }
              .account-menu {
                position: absolute;
                right: 28px;
                top: 101px;
                background: #ffffff;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
                border-radius: 4px;
                z-index: 5000;
                width: 100%;
                max-width: 230px;
                padding: 5px 0;
                opacity: 0;
                visibility: hidden;
                transition-property: opacity, visibility;
                transition-duration: 0.4s, 0s;
                transition-delay: 0s, 0.4s;
                &:after {
                  width: 20px;
                  height: 20px;
                  transform: rotate(-225deg);
                  background: white;
                  content: " ";
                  position: absolute;
                  right: 20px;
                  top: -10.5px;
                  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
                  z-index: 50;
                  @media (max-width: 1000px) {
                    right: 76px;
                  }
                }
                &:before {
                  width: 24px;
                  height: 24px;
                  transform: rotate(-225deg);
                  background: white;
                  content: " ";
                  position: absolute;
                  right: 18px;
                  top: -8.5px;
                  z-index: 100;
                  @media (max-width: 1000px) {
                    right: 74px;
                  }
                }
                li {
                  border-top: 1px #e7e8eb solid;
                  font-weight: 600;
                  font-size: 14px;
                  margin: 0;
                  display: block;
                  padding: 0;
                  text-align: left;
                  &:nth-child(1) {
                    border-top: 0;
                  }
                  a {
                    padding: 20px 22px;
                    display: block;
                    border-right: none;
                    color: #1A1F23;
                  }
                  h4 {
                    color: #334958;
                    padding: 15px 20px 10px 20px;
                    display: block;
                    font-weight: 600;
                  }
                  ul {
                    padding: 0 0 10px 0;
                    li {
                      border-top: none;
                      &:nth-child(1) {
                        border-top: 0;
                      }
                      a {
                        color: #1A1F23;
                        padding: 0 20px 5px 20px;
                        display: block;
                        font-size: 12px;
                      }
                    }
                  }

                }
              }
              &.active {
                .account-menu {
                  visibility: visible;
                  opacity: 1;
                  transition-delay: 0s, 0s;
                }
              }
            }

          }


          
          
          .notifications_info {
          }

          a.checkout-btn {
            vertical-align: middle;
            text-decoration: none;
            position: relative;
            .icon{
              display: block;
              background-image: url(../img/ico_cart_active.svg);
              background-size: 30px 32px;
              background-position: center;
              background-repeat: no-repeat;
              height: 34px;
              width: 36px;
              margin-left: 15px;
              @media (max-width: 600px) {
                background-size: 30px 25px;
                height: 30px;
                margin-left: .5rem;
              }
            }
            &:after {
              content: attr(data-total);
              position: absolute;
              top: -10px;
              right: 5px;
              display: inline-block;
              width: 20px;
              height: 20px;
              color: #1A1F23;
              border-radius: 50%;
              font-size: 13px;
              text-align: center;
              line-height: 20px;
              font-weight: bold;
            }
            &[data-total="0"]:after {
              display: none;
            }
            &[data-total="0"]{
              .icon{
                background-image: url(../img/ico_cart.svg);
              }
            }
          }
        }
      }
    }
  }
  .main-product-menu {
    background-color: #fff;
    width: 100%;
    transition: all 0.2s ease-in-out;
    padding: 10px 0 50px 0;
    opacity: 0;
    display: none;
    height: 0;
    overflow: hidden;
    z-index: 100;
    overflow: visible;


   @media (max-width: 600px) {
       top: 90px;
    }
   .menu_trigger{
      display: none;
    }
    .sub_nav_inner-wrap{
      display: flex;
      flex-wrap: nowrap;
      display: flex;
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;
      position: relative;
      @media (max-width: 650px) {
        width: 100%;
      }
    }
    .sub_nav_wrap{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      width: 100%;
    }
    .sub_nav_col{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 1rem;
      @media (max-width: 900px) {
        padding-top: 4rem;
      }
      @media (max-width: 600px) {
        padding-top: 0;
      }

        ul.parents {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          width: 75%;
          padding: 20px 0;
          @media (max-width: 1200px) {
            width: 66.66%;
          }
          @media (max-width: 900px) {
            width: 100%;
          }
           
          position: relative;
          @media (max-width: 1120px) {
            padding-left: 0;
          }
          li {
            width: 33%;
            position: relative;
            @media (max-width: 1200px) {
              width: 50%;
            }

            @media (max-width: 650px) {
              width: 100%;
            }

            a.parent_link {
              color:  #1A1F23;
              font-size: 11px;
              font-family: bitter, serif;
              font-weight: 700;
              font-size: 16px;
              line-height: 1.2;
              width: 100%;
              border-radius: 4px;
              margin: 0 0 1px 0;
              padding: 1rem 1.5rem;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              height: fit-content;
              text-decoration: none;
              &:hover{
                background-color: #eeeeee;
                cursor: pointer;
                text-decoration: none;
                &:after{
                  opacity: 1;
                }
              }
              @media (max-width: 1230px) {
                font-size: 14px;
              }
              @media (max-width: 800px) {
                width: 100%;
              }
              &:after{
                margin-left: 10px;
                border-radius: 50%;
                display: block;
                background-image: url("../img/ico_arrow_left_dark.svg");
                content: ' ';
                background-size: 12px 12px;
                background-position: center;
                background-repeat: no-repeat;
                height: 26px;
                width: 26px;
                opacity: 0;
              }



              &.buynow {
                font-size: 26px;
                justify-content: flex-start;
                padding-top: 20px;
                padding-bottom: 20px;
                &:after{
                  margin-left: 10px;
                  border-radius: 50%;
                  display: block;
                  background-image: url("../img/ico_arrow_left.svg");
                  background-color: #00AF0D;
                  content: ' ';
                  background-size: 12px 12px;
                  background-position: center;
                  background-repeat: no-repeat;
                  height: 20px;
                  width: 20px;
                  opacity: 1;
                }
                &:hover{
                  background-color: #fff;
                  a{
                    text-decoration: underline;
                  }
                }
              
              }
            }

            ul.submenu {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              padding-left: 0;
              position: absolute;
              top: 0;
              opacity: 0;
              pointer-events: none;
              left: 0;
              width: 100%;
              z-index: 10;
              padding: 4.5rem 1.5rem 1.5rem;
              background-color: #eeeeee;
              border-radius: 4px;

              @media (max-width: 650px) {
                width: 100%;
                position: relative;
                left: auto;
                top: auto;
                height: 0;
                padding:0;
                background-color: #ffffff;

              }



              li {
                width: fit-content;
                display: flex;
                
                
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 2px solid #eeeeee;
                &:hover, .active{
                  border-bottom: 2px solid #00AF0D;
                  cursor: pointer;
                  text-decoration: none;
                  a{
                    text-decoration: none;
                    color: #1a1f23;
                    
                  }
                  &:after{
                    opacity: 1;
                  }
                }
      
                a {
                  margin: 0;
                  font-weight: 500;
                  font-size: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 1.3rem;
      
                  color: #3f3f3f;
                  padding: 10px 0 0 0;
                  margin-bottom: 0;
                  margin-top: 4px;
                  &.active,
                  &:hover {
                  }
                  &.parent_category_link{
                    font-weight: bold;

                
                  }
                }
                &.parent_link_outter{
                  border-bottom: none;
                  .about_links{
                    display: flex;
                    justify-content: flex-start;
                    margin: 0;
                    font-weight: 500;
                    font-size: 12px;
                    align-items: center;
                    font-size: 1.3rem;
                    line-height: 0.8;
                    color: #3f3f3f;
                    margin-top: 10px;
                    a{
                      font-weight: bold;
                      text-decoration: underline;
                      margin-top: 0;
                      padding-top: 0; 
                    }
                    span{
                      margin: 0 7px;
                    }
                  }
                }
                
              }
            }
            &.active{
              .submenu{
                opacity: 1;
                pointer-events: inherit;
                z-index: 10;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.20);
                @media (max-width: 800px) {
                  height: auto;
                  padding-left: 12px;
                }
                @media (max-width: 650px) {
                  box-shadow: none;
                }
              }
              a.parent_link {
                background-color: #eeeeee;
                cursor: pointer;
                text-decoration: none;
                z-index: 30;
                position: relative;
                
                &:after{
                  opacity: 1;
                  background-image: url("../img/ico_arrow_left.svg");
                  background-color: #00AF0D;
                }
                &.buynow {
                  background-color: #fff;
                  text-decoration: underline;
                  &:after{
                    background-image: url("../img/ico_arrow_left.svg");
                  }
                }
              }
            }
          }
        }
        .buynow_menu{
          width: calc(25% - 20px);
          background-color:#00AF0D;
          border-radius: 4px;
          padding: 30px;
          margin-left: 20px;
          @media (max-width: 1200px) {
            
            width: calc(33% - 20px);
          }
          @media (max-width: 900px) {
            width: 100%;
            margin-left: 0;
          }
          h3,li,a{
            color: #fff;
          }
          h3{
            margin-bottom: 15px;
          }
          li{
            margin-bottom: 10px;
          }
          ul{
            display: flex;
            flex-wrap: wrap;
            li{
              width: 100%;
            }
            @media (max-width: 900px) {
              width: 100%;
              li{
                width: 50%;
              }
            }
            @media (max-width: 650px) {
              width: 100%;
              li{
                width: 100%;
              }
            }
          }
        }
      

    }
  }

  .site-branding {
    position: absolute;
    top: 0;
    left: -15px;
    z-index: 1000;
    transition: all .2s ease-in-out;
    @media (max-width: 600px) {
      width:35%;
    }
    @media (max-width: 460px) {
      width:30%;
    }
    a.site-logo {
      margin-top: 20px;
      img {
        //temporary - need to export larger logo, prefferably svg
        width: 260px;
        transition: all .2s ease-in-out;
        @media (max-width: 1600px) {
          width: 230px;
        }
        @media (max-width: 700px) {
          width: 200px;
        }
        @media (max-width: 600px) {
          width:100%;
        }
      }
    }
  }
  .site-menu {
    display: block;
    position: relative;
    text-align: right;
    ul {
      li {
        display: inline-block;
        font-size: 14px;
        margin-left: 15px;
        &.has-submenu {
          @media (max-width: 754px) {
            display: none;
          }
        }
        a {
          color: #334958;
        }
        a.checkout-btn {
          vertical-align: middle;
          text-decoration: none;
          margin-top: 3px;
          position: relative;
          .icon {
            font-size: 26px;
          }
          &:after {
            content: attr(data-total);
            position: absolute;
            top: -20px;
            right: -10px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: #b32317;
            color: white;
            border-radius: 50%;
            font-size: 13px;
            text-align: center;
            line-height: 20px;
            font-weight: bold;
          }
          &[data-total="0"]:after {
            display: none;
          }
        }
        &.login-btn {
          border: 1px #334958 solid;
          text-align: center;
          padding: 11.5px 15px;
          border-radius: 4px;
          font-style: normal;
          font-weight: 600;
        }
        i {
          font-size: 20px;
          color: #334958;
          margin-left: 3px;
          position: relative;
          top: 4px;
        }
        span.account-dropdown-tigger {
          cursor: pointer;
          display: block;
          position: relative;
          &:active {
            top: 1px;
          }
        }
        a.notification-link {
          position: relative;
          .counter {
            line-height: 12px;
            background: $green;
            color: #fff;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid #fff;
            position: absolute;
            top: -16px;
            right: -12px;
            font-size: 9px;
            font-weight: 600;
            text-align: center;
            display: inline-block;
            padding: 4px;
            min-width: 24px;
          }
        }

        &.burger-menu {
          display: none;
          @media (max-width: 750px) {
            display: inline-block;
            ul.account-menu {
              right: -23px;
              max-height: 80vh;
              overflow: scroll;
            }
          }
        }
      }
    }
  }
}

.navbar {
  .toolbar,
  .categories-btn {
    display: none;
  }
}

.site-header .site-menu {
  li {
    .product-sub-menu {
      position: absolute;
      top: 55px;
      display: block;
      background: #ffffff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
      border-radius: 4px;
      z-index: 5000;
      width: 100%;
      max-width: 485px;
      //max-width: 273px;
      padding: 5px 0;
      opacity: 0;
      visibility: hidden;
      transition-property: opacity, visibility;
      transition-duration: 0.4s, 0s;
      transition-delay: 0s, 0.4s;
      text-align: left;
      li {
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        padding: 0;
        text-align: left;
        background-color: white;
        background: white;
        display: inline-block;
        vertical-align: top;
        width: 240px;
        a {
          color: #334958;
          padding: 15px 20px;
          display: block;
          background: white;
        }
      }
      &:before {
        width: 25px;
        height: 31px;
        transform: rotate(-225deg);
        background: white;
        content: " ";
        position: absolute;
        left: 20px;
        top: -12.5px;
        z-index: 100;
      }
      &:after {
        width: 25px;
        height: 25px;
        transform: rotate(-225deg);
        background: white;
        content: " ";
        position: absolute;
        left: 20px;
        top: -12.5px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
        z-index: 50;
      }
    }
    #buynow-sub-menu.product-sub-menu {
      max-width: 245px;
    }
    &.active {
      ul {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s, 0s;
      }
    }
  }
}


.nav-currency {
  display: flex;
  background-color: #E9ECEE;
  border-radius: 4px;
  color: #1A1F23;
  flex-direction: row;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  overflow: hidden;
  margin-left: 1rem;

  a.nav-link {
    margin: 0;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    padding: 1.5rem;
    border-radius: unset;
    &.active {
      background-color: $green;
      color:#fff !important;
    }
  }
}