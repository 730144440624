
$title-font:"bitter", serif;
$title-font-family:	"proxima-nova", sans-serif;
$standard-font-family: "proxima-nova", sans-serif;


$container: 1200;
$container_small: 1100;

$yellow: #F5D200;
$gold: $yellow;
$red: #B6051A;
$blue: #00A8B7;
$darkblue: #2591A4;
$lightblue: #E6F5F7;
$textshadow: 0px 0px 4px rgba(0, 0, 0, 0.67);
$green: #00AF0D;
$titleblue: #334958;
$brandingblue: #1dbef4;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;

$quoteformmaxwidth: 600px;
$quoteforminnerwidth: 810px;

$bold: 700;
$semi-bold: 600;
$medium: 500;
$regular: 400;
$light: 300;


$imgpath: '../../../../assets/img/';
