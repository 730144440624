.news-inner-wrap{
  max-width: 900px;
    width: 90%;
    margin: auto;
}


.news-mid{
  padding: 40px 0 50px;
  margin-top: 50px;
}

h1.news-page-title {
  font-size: 38px;
  font-weight: 600;
  color: #334958;
  margin: 10px 0;
}
ul.listNews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 40px -20px;
  .news-item-wrap{
    width: calc(33% - 40px);
    margin: 0 20px 40px 20px;
    @media (max-width: 1000px) {
      width: calc(50% - 40px);
    }
    @media (max-width: 640px) {
      width: calc(100% - 40px);
      .news-item img {
          height: 75vw;
          max-height: 75vw;
      }
    }
    @media (max-width: 600px) {
      .news-item img {
          height: 75vw;
          max-height: 75vw;
      }
    }
  }

}

.news-inner-wrap {
  max-width: 800px;
  margin: 0 auto;

}
.news-page-body {
  h2,
  h3 {
    margin: 30px 0 10px;
    font-size: 30px;
  }
  p {
    margin-bottom: 1.5em;
    line-height: 24px;
    font-size: 15px;
    font-weight: 400;
    color: #565656;
  }
}

.news-featured-image {
  margin: 25px 0;
}
