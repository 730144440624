

.footer{
  background-color: #fff;
  padding: 8rem 0;
  .footer-top{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    @media (max-width: 1000px) {
      margin: 0 ;
    }
    @media (max-width: 600px) {
      flex-direction: column;
    }
    .item{
      width: 50%;
      @media (max-width: 600px) {
        width: 100%;
      }
      ul{
        display: flex;
        flex-direction: column;
        li {
          width: 100%;
          list-style: none;
          @media (max-width: 600px) {
            text-align: center;
          }
          a{
              @extend .title-font;
              font-size: 3.3rem;
              padding:0 0 0.5rem 0 ;
              margin-bottom: 1rem;
          }
        }
      }
      h4{
        @extend .title-font;
        font-size: 3.3rem;
        padding:0 0 0.5rem 0 ;
        line-height: 0.5;
        margin-bottom: 1rem;
        @media (max-width: 900px) {
          line-height: 1.5;
        }
        @media (max-width: 600px) {
          display: none;
        }
      }
      &:nth-child(2){
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        @media (max-width: 900px) {
          justify-content: flex-start;
        }
        @media (max-width: 600px) {
          justify-content: center;
          .footer-contact{
            padding-top: 4rem;
            display: flex;
            justify-content: center;
            width: 100%;
            flex-direction: column;
            align-items: center;
          }
        }
        a{
          font-size: 1.5rem;
          color: #1A1F23;
          letter-spacing: 0;
          
        }
        .social-links {
          margin: 2rem 0;
          a {
            display: inline-block;
            margin-right: 10px;
            i {
              font-size: 3.4rem;
            }
          }
        }
      }
    }
  }
  .footer-bottom{
    padding-top: 6rem;
    opacity: 0.5;
    @media (max-width: 600px) {
      padding-top: 6rem;
      opacity: 0.5;
      text-align: center;
      width: 80%;
      margin: 0 auto;
    }
    p, a{
      font-size: 1.4rem;
      color: #1A1F23;
      letter-spacing: 0;
    }
  }
}
.footer-credits{

  display: flex;
  flex-direction: column;
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 6rem;
  text-align: center;
  img{
    padding-bottom: 3rem;
  }
  p, a{
    opacity: 0.5;
    font-size: 1.2rem;
    color: #1A1F23;
    letter-spacing: 0;
  }
}






/* Old Footer Styles can be deleted of moved - still need the currency toggle */

.site-footer {

  .btm {
    padding: 50px 0;
    background-color: #fff;;
    .row {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 0 15px;
      @media (max-width: 816px) {
        flex-direction: column;
      }
      .col {
        margin: 0;
        padding: 0;
        flex-basis: auto;
        flex-grow: 0;
        width: auto;
        .footer-logo {
          height: 93px;
          margin-left: -18px;
          @media (max-width: 600px) {
            margin-left: 0;
          }
        }
        &.copyright {
          font-size: 11px;
          font-weight: $light;
       
          @media (max-width: 600px) {
            font-size: 12px;
          }
          @media (max-width: 450px) {
            font-size: 11px;
          }
          a {
            text-decoration: underline;
          }
        }
        &:nth-child(1) {
          flex-grow: 1;
        }
        .contact-links {
          margin: 15px 0;
        }
  

      }
      &:nth-child(1) {
        .col {

          &:nth-child(1) {
            padding-right: 40px;

          }
          &:nth-child(2) {
            text-align: right;
            max-width: 580px;
            @media (max-width: 970px) {
              max-width: 460px;
            }
            @media (max-width: 816px) {
              max-width: 100%;
              text-align: left;
            }
            p {
              font-size: 11px;
         
              line-height: 1.7;
              margin: 0;
            }
          }
          @media (max-width: 816px) {
            width: 100%;
          }
        }

      }
      &:nth-child(2) {

      }
    }
  }

  .cart-menu {
    display: none;
  }
  .footernav{
    display: flex;
    margin-bottom: 30px;
    justify-content: flex-end;
    @media (max-width: 816px) {
      border-top: 1px solid #313539;
      justify-content: end;
      padding: 30px 0 0 ;
      margin-top: 30px;
    }
    @media (max-width: 508px) {
      flex-direction: column;
    }
    li{
      font-size: 13px;
      margin-left: 20px;
      @media (max-width: 816px) {
        margin-left: 0;
        margin-right: 20px;
      }
      @media (max-width: 508px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .footer-sponsors {
    display: flex;
    justify-content: flex-end;
    align-content: stretch;
    padding: 30px 0;
    border-top: 1px solid #313539;
    @media (max-width: 816px) {
      justify-content: end;
    }
    div {
      margin-left: 15px;
      width: auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }


  }
}


/* Absolute Center Spinner */
.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  backdrop-filter: blur(275px);
  opacity: 0.7;
  z-index: 10000;
  display: none;
  align-items: center;
  justify-content: center;
  &.show{
    display: flex;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(43, 163, 49);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color:  rgb(43, 163, 49) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}