/* Product Overview */



.product-wrapper {
  display: flex;
  flex-wrap: nowrap;

  .product-wrapper-header {
    padding: 100px 0 20px 0;
    h1 {
      font-size: 26px;
      color: #334958;
      line-height: 1.3;
      margin: 0;
      border-bottom: 2px #e6e7e9 solid;
      padding: 0 0 10px 0;
    }
    h2 {
      font-size: 15px;
      span {
        color: #00AF0D;
      }
    }
    p {
      font-size: 15px;
      color: #939393;
      line-height: 1.35;
      max-width: 540px;
      margin: 0;
    }
  }
  .reset-search-btn {
    font-size: 12px;
    color: #1dbef4;
    text-decoration: underline;
  }
  .product-grid-wrap {
    padding-top: 55px;
    padding-bottom: 50px;
    margin-top: 0;
  }
}
.product-grid {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
  .product-grid-item {
    width: calc(25% - 30px);
    margin: 15px;
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    text-align: left;
    @media (max-width: 1080px) {
      width: calc(33% - 30px);
    }
    @media (max-width: 800px) {
      width: calc(50% - 30px);
    }
    @media (max-width: 480px) {
      width: calc(100% - 30px);
    }

    .product-card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
    }

    .product-thumb {
      width: 100%;
      display: block;
      height: 140px;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      img {
        opacity: 0;
        &.featured {
          opacity: 1;
          position: absolute;
          top: 5px;
          right: 10px;
          z-index: 100;
        }
      }
    }
    .product-card-body {
      padding: 10px;
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      div {
        width: 100%;
      }
      h3 {
        font-size: 13px;
        color: #334958;
        font-weight: 600;
        font-family: $title-font-family;
        a {
          color: #334958;
        }
        @media (max-width: 800px) {
          font-size: 15px;
        }
        @media (max-width: 480px) {
          font-size: 14px;
        }
      }
      h4 {
        font-size: 36px;
        color: #334958;
        font-weight: 700;
        font-family: $title-font-family;
        line-height: 1.1;
        margin: 15px 0;
        small {
          display: block;
          font-family: $standard-font-family;
          font-size: 10px;
          color: #939393;
          font-weight: 600;
        }
        @media (max-width: 800px) {
          font-size: 30px;
        }
        @media (max-width: 480px) {
          font-size: 24px;
        }
      }
      a.btn {
        display: block;
        font-weight: 600;
        font-size: 11px;
        text-align: center;
        padding: 8px 15px;
        margin-top: 4px;
      }
    }
  }
}
.filter-dropdowns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 20px;
  .form-group {
    width: calc(25% - 30px);
    @media (max-width: 700px) {
      width: calc(50% - 30px);
    }
    @media (max-width: 380px) {
      width: calc(100% - 30px);
    }
    margin: 0 15px 20px;
    select {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
      margin-top: 3px;
    }
  }
}

/* Product Compontents */
.product-brand-wrap-details {
  margin-bottom: 9px;
  img {
    height: 37px;
  }
}
.product-brand-wrap {
  margin-bottom: 9px;
  img {
    height: 17px;
  }
}
/* Product Details */
.product-header {
  padding: 40px 0 50px;
  margin-top: 50px;
  @media (max-width: 960px) {
    padding-bottom: 0;
  }
  .icheckbox_flat-blue {
    display: none;
  }
  .inner-wrap {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    @media (max-width: 900px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .col {
      width: calc(50% - 50px);
      padding: 0;
      flex-grow: 0;
      flex-basis: auto;
      @media (max-width: 900px) {
        width: 100%;
        &:nth-child(1) {
          margin-bottom: 35px;

        }
      }
      .product-brand-wrap {
        margin-bottom: 9px;
        img {
          height: 30px;
        }
      }
      .product-gallery {
        position: relative;
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.04);
        img {
          width: 100%;
          border: 1.5rem solid #fff;
          &.featured {
            position: absolute;
            top: 5px;
            right: 10px;
            z-index: 100;
            width: auto;
          }
        }
      }

      .title-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 0 1.5rem;
        .category-title {
          
          a {
            font-weight: $medium;
            font-size: 14px;
            color: #666;
            border-left: 1px solid #666;
            padding: 0 10px;
            &:nth-child(1) {
              border-left: none;
              padding: 0 10px 0 0;
            }
          }
        }
  
        .product-brand-wrap {
          margin-bottom: 9px;
          img {
            height: 22px;
          }
        }
      }

      h1 {
        font-size: 4rem;
        color: #334958;
        line-height: 1.5;
        margin: 0 0 20px 0;
        font-family: $title-font;
        @media (max-width: 600px) {
          font-size: 26px;
        }
        @media (max-width: 450px) {
          font-size: 24px;
        }
      }
      .sku-item {
        width: 100%;
        .icheckbox_flat-blue {
          display: none;
        }
        form {
          margin: 0;
        }
        .sku-item-inner {
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          margin-bottom: 20px;
          .label-info {
            padding: 5px 0;
            border-left: 1px #d8d8d8 solid;
            margin-left: 40px;
            padding-left: 40px;
            margin-bottom: 15px;
            width: calc(50% - 25px);
            line-height: 1.5;
            white-space: break-spaces;
            text-align: left;
            @media (min-width: 1250px) {
              width: calc(33% - 25px);
              &:nth-child(3n + 1) {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
              }
            }
            @media (min-width: 900px) and (max-width: 1250px) {
              &:nth-child(2n + 1) {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
              }
            }
            @media (max-width: 900px) {
              width: calc(33% - 25px);
              &:nth-child(3n + 1) {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
              }
            }
            @media (max-width: 600px) {
              width: calc(50% - 25px);
              &:nth-child(3n + 1) {
                border-left: 1px #d8d8d8 solid;
                margin-left: 40px;
                padding-left: 40px;
              }
              &:nth-child(2n + 1) {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
              }
            }
            @media (max-width: 350px) {
              width: calc(100%);
              border-left: 0;
              margin-left: 0;
              padding-left: 0;
              &:nth-child(3n + 1) {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
              }
            }

            label {
              display: block;
              text-align: left;
              font-weight: 600;
              font-size: 12px;
              color: #b3b3b3;
              margin-bottom: 5px;
            }
            .value {
              display: block;
              font-weight: 600;
              font-size: 17px;
              color: #5a5a5a;
              text-align: left;
            }
          }

          &.product-details-overview {
            span.label-info {
              display: inline-block;
              width: calc(33% - 1px);
              text-align: left;
              font-weight: 500;
              font-size: 11px;
              color: #b3b3b3;
              margin-left: 0;
              b {
                font-weight: 500;
                font-size: 17px;
                color: #5a5a5a;
                display: block;
                margin-top: 5px;
              }
              &:nth-child(3n + 1) {
                border-left: 0;
                padding-left: 0;
                margin-left: 0;
              }
              @media (max-width: 1250px) {
                width: calc(33% - 1px);

                &:nth-child(2n + 1) {
                  border-left: 1px #d8d8d8 solid;
                  margin-left: 0;
                  padding-left: 40px;
                }
                &:nth-child(3n + 1) {
                  border-left: 0;
                  padding-left: 0;
                  margin-left: 0;
                }
              }
              @media (max-width: 1100px) {
                width: calc(50% - 3px);
                padding-left: 40px;
                &:nth-child(3n + 1) {
                  border-left: 1px #d8d8d8 solid;
                  padding-left: 40px;
                  margin-left: 0;
                }
                &:nth-child(2n + 1) {
                  border-left: 0;
                  margin-left: 0;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
      .price-wrap {
        background: #ffffff;
        border: 1px solid #e8e9ec;
        margin: 20px 0;
        display: flex;
        padding: 0;
        @media (max-width: 450px) {
          flex-direction: row;
          flex-wrap: wrap;
        }
        .col {
          padding: 15px 30px 25px;
          width: 50%;
          border-left: 1px #e8e9ec solid;
          @media (max-width: 450px) {
            width: 100%;
          }
          &:nth-child(1) {
            border-left: 0;
            margin-top: 0;
          }
          span {
            font-size: 13px;
            color: #334958;
            display: flex;
            flex-direction: column;
            font-weight: 700;
            &.lbl {
            }
            &.value {
              font-family: $title-font-family;
              font-size: 40px;
              color: #334958;
              line-height: 1;
              @media (max-width: 1100px) {
                font-size: 32px;
              }
            }
          }
        }
      }
    }
  }
}

.tab-wrap {
  background: #fff;
  padding-bottom: 45px;
  border-top: 2px #e3e3e3 solid;
  @media (max-width: 960px) {
    display: none;
  }
  .tab-selection-header {
    border-bottom: 2px solid #e3e3e3;
    .inner-wrap {
      max-width: 1195px;
      margin: 0 auto;
      ul.nav-tabs {
        border: 0;
        border-left: 2px #e3e3e3 solid;
        flex-wrap: nowrap;
        align-content: stretch;
        li {
          margin: 0;
          padding: 0;
          display: block;
          text-align: center;
          width: 100%;
          a.nav-link {
            font-weight: $medium;
            font-size: 14px;
            color: #334958;
            border: 0;
            color: #334958;
            border-right: 2px #e3e3e3 solid;
            padding: 34px 10px;
            position: relative;
            @media (max-width: 1150px) {
              padding: 29px 10px;
            }
            &:active {
              top: 1px;
            }
            &.active {
              &:after {
                content: " ";
                height: 7px;
                width: calc(100% + 4px);
                background: #4e9ed7;
                bottom: 0;
                left: -2px;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
  .tab-content {
    .inner-wrap {
      margin: 55px auto 25px;
      padding: 0 10px;
      .tab-pane {
        padding-top: 45px;
        font-size: 15px;
        color: #939393;
        line-height: 1.4;
        p {
          margin-bottom: 1.5em;
        }
      }
      h2 {
        font-size: 30px;
        color: #334958;
        font-style: normal;
        font-weight: $medium;
        margin-bottom: 25px;
        @media (max-width: 900px) {
          font-size: 26px;
        }
        @media (max-width: 600px) {
          font-size: 22px;
        }
        @media (max-width: 450px) {
          font-size: 20px;
        }
      }
    }
  }
}

.general-txt {
  font-size: 15px;
  color: #939393;
  line-height: 1.4;
  p {
    margin-top: 1.5em;
    font-size: 13px;
    color: #757575;
    line-height: 1.7;
    &:nth-child(1) {
      margin-top: 0;
    }
    strong {
      font-weight: $semi-bold;
      color: $titleblue;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  ul {
    margin: 10px 0 10px 10px;
    list-style-type: disc;
    li {
      padding: 5px;
      font-size: 13px;
      margin: 5px;
      color: #757575;
    }
  }
}
.specifications-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px #e9e9e9 solid;
  .badge {
    display: block;
    width: 100%;
    font-size: 14px;
    color: #000000;
    letter-spacing: -0.05px;
    padding: 0;
    border-radius: 0;
    display: flex;
    border: 1px #e9e9e9 solid;
    border-top: 0;
    label {
      font-weight: 600;
      padding: 20px 15px;
      border-right: 1px #e9e9e9 solid;
      width: 215px;
      line-height: 1.5;
      white-space: break-spaces;
      text-align: left;
    }
    span.value {
      padding: 20px 15px;
      font-size: 14px;
      font-weight: 500;
      width: calc(100% - 215px);
      text-align: left;
      line-height: 1;
    }
    &:nth-child(odd) {
      background: #f6f7fa;
    }
  }
}

ul.product-thumbs {
  display: flex;
  flex-wrap: wrap;
  li {
    width: calc(25% - 20px);
    margin: 10px;
    height: 180px;
    overflow: hidden;
    a {
      img {
        width: 100%;
      }
    }
  }
}

.product_dialog_overview {
  background-color: #fff;
  .top {
    padding: 30px 40px;
    @media (max-width: 450px) {
      padding: 20px 25px;
    }
    h1 {
      font-size: 26px;
      color: #334958;
    }
    .sku-item-inner {
      margin-top: 15px;
      span.label {
        border-left: 1px #dbdbdb solid;
        padding-left: 15px;
        margin-left: 15px;
        text-align: left;
        width: calc(33% - 20px);
        /*
        label {
          text-align: left;
          font-size: 12px;
          color: #b3b3b3;
          margin-bottom: 3px;
        }
        span.value {
          font-size: 15px;
          color: #5a5a5a;
        }*/
        &:nth-child(3n + 1) {
          border-left: 0;
          padding-left: 0;
          margin-left: 0;
        }
        @media (max-width: 900px) {
          width: calc(50% - 20px);
          padding-left: 25px;
          &:nth-child(3n + 1) {
            border-left: 1px #dbdbdb solid;
            padding-left: 25px;
            margin-left: 15px;
          }
          &:nth-child(2n + 1) {
            border-left: 0;
            padding-left: 0;
            margin-left: 0;
          }
        }
        @media (max-width: 525px) {
          width: calc(100% - 20px);
          padding-left: 0;
          border-left: 0;
          margin-left: 0;
          &:nth-child(3n + 1) {
            border-left: 0;
            padding-left: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
  form {
    margin: 0;
    border: 0;
    padding: 0;
  }
  .mid {
    border-bottom: 1px #d7d7d7 solid;
    padding: 30px 40px;
    @media (max-width: 450px) {
      padding: 20px 25px;
    }
    h4 {
      font-size: 15px;
      color: #334958;
    }
    ul.select_extras {
      margin: 10px -10px;
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 11px;
        color: #5a5a5a;
        background: #f5f5f5;
        border: 1px solid #e8e9ec;
        margin: 5px 10px;
        padding: 0;
        display: flex;
        width: calc(50% - 20px);
        &:nth-child(1) {
          border-top: 1px solid #e8e9ec;
        }
        .iCheck-helper,
        .icheckbox_flat-blue {
          display: none;
        }
        span.lbl {
          width: calc(100% - 125px);
          display: inline-block;
          padding: 10px;
        }
        span.value {
          width: 125px;
          display: inline-block;
          padding: 10px;
          border-left: 1px #e8e9ec solid;
        }
        &.selected {
          background: #334958;
          color: #ffffff;
        }
      }
      @media (max-width: 625px) {
        li {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
      }
      @media (max-width: 350px) {
        li {
          margin: 5px 10px;
          flex-wrap: wrap;
          flex-direction: row;
          span.lbl {
            width: calc(100% - 20px);
            display: block;
          }
          span.value {
            width: calc(100% - 20px);
            display: block;
            border-left: 0 #e8e9ec solid;
          }
        }
      }
    }
  }
  .btm {
    padding: 30px 40px;
    @media (max-width: 450px) {
      padding: 20px 25px;
    }
  }
}

ul.accordian-mobile {
  display: none;
  @media (max-width: 960px) {
    display: block;
    border-top: 1px solid #e3e3e3;
  }
  li {
    background-color: #fff;
    color: #444;
    cursor: pointer;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    h3.accordion {
      padding: 18px;
      color: #334958;
      position: relative;
      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f107";
        color: #334958;
        position: absolute;
        top: 12px;
        right: 30px;
        font-size: 21px;
      }
      &.active,
      &:hover {
        background-color: #4e9ed7;
        color: #fff;
        &:after {
          content: "\f106";
          color: #fff;
        }
      }
    }

    .accordion-panel {
      padding: 0 18px;
      background-color: white;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      &.active {
        max-height: none;
      }
      .accordion-panel-inner {
        padding: 18px 0;
      }
    }
  }
}

form.products-filter-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;

  width: auto;
  .products-filter-col {
    width: 100&;
    margin-bottom: 16px;;
    label {
      font-size: 11px;
      font-weight: 800;
      text-transform: uppercase;
      color: #676767;
      display: block;
      margin-bottom: 5px;
    }


    .selected-wrap {
      background: #ffffff;
      position: relative;
      span.selected {
        display: block;
        padding: 15px 17px;
        font-size: 13px;
        color: #4c4c4c;
        cursor: pointer;
        position: relative;
        &:after {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f107";
          color: #1a1f23;
          position: absolute;
          top: 9px;
          right: 20px;
          font-size: 23px;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        border-left: 1px solid #e8e9ec;
        border-right: 1px solid #e8e9ec;
        border-bottom: 1px solid #e8e9ec;
        width: 100%;
        background: #fff;
        z-index: 1000;
        &.show {
          display: block;
        }
        li {
          font-size: 13px;
          color: #939393;
          padding: 10px 18px;
          border-top: 1px solid #e8e9ec;
          position: relative;
          cursor: pointer;
          &.disable {
            opacity: 0.3;
            //pointer-events: none;
          }
          .icheckbox_flat-blue {
            position: absolute;
            top: 7px;
            right: 17px;
            pointer-events: none;
          }
        }
      }
    }
  }
  &.hide-from-mobile {
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.product-filter-btn {
  font-family: $title-font-family;
  font-size: 18px;
  font-weight: 700;
  color: #334958;
  line-height: 1.3;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
  display: none;
  &:active {
    top: 1px;
  }
  @media (max-width: 600px) {
    display: block;
  }
}

h5.related-news-date-title {
  margin: 15px 0;
  font-size: 12px;
  font-weight: 600;
}


