.news-wrap{
    .news-title{
        text-align: left;
        display: flex;
        flex-direction: column;
        padding: 6rem 0 6rem 0;
        @media (max-width: 1000px) {
           text-align: center;
           padding: 0 0 6rem 0;
        }

        
        h2{
            color: #1A1F23;
        }

    }
    .latest_news{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -30px 8rem 10%;
        
        @media (max-width: 1000px) {
            margin: auto;
            max-width: 100%;
            width: 100%;
            margin-bottom: 40px;
        }
        .item{
            width: calc(50% - 60px);
            margin: 0 30px;
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            overflow: hidden;
            align-items: flex-start;
            position: relative;
            transition: all .2s ease-in-out;
            background-color: #fff;
            @media (max-width: 800px) {
                width: calc(50% - 40px);
                margin: 0 20px;
            }
            @media (max-width: 600px) {
                width: calc(100% - 40px);
                margin: 0 7% 4rem;

            }
            img{
                width: 100%;
                max-height: 34rem;
                height: 25vw;
                border-radius: 4px;
                object-fit: cover;
                overflow: hidden;
                margin-bottom: 2rem;
                @media (max-width: 1000px) {
                    height: 32vw;
                    max-height: 32vw;
                }
                @media (max-width: 600px) {
                    height: 45vw;
                    max-height: 45vw;
    
                }
            }
            .text_outter{
                display: flex;
                flex-direction: column;
                padding: 3rem;
                width: 100%;;
                h4{
                    padding-bottom: 1rem;
                }
                h3{
                    @extend .body-font;
                    padding:0 0 0.5rem 0 ;
                    margin-bottom: 1rem;
                    width: 100%;
                    text-align: left;
                    font-size: 2.8rem;
                    line-height: 1.4;
                    @media (max-width: 1000px) {
                        font-size: 2.5rem;
                    }
                }
            }

            &:nth-child(2){
                top: -13rem;
                @media (max-width: 1000px) {
                    top: 0;
                }
            }
            &:hover{
                text-decoration: none;
                transform: scale(1.04);
            }
        }
    }

    .button_area{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: -12rem;
        height: 0;
        margin-bottom: 16rem;
        @media (max-width: 1000px) {
            margin-top: 0;
            justify-content: center;
            margin-bottom: 6rem;
        }
    }
}