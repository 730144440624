.btn {
  background: #00AF0D;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: $regular;
  line-height: 1;
  border-radius: 4px;
  padding: 15px 25px;
  display: inline-block;
  border:0;
  cursor: pointer;
  position: relative;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(#00AF0D, 0.9);
  }
  &:active {
    top: 1px;
  }
  &.secondary-btn {
    background: #1dbef4;
    &:active,
    &:focus,
    &:hover {
      background: rgba(#1dbef4, 0.9);
    }
  }
  &.third-btn {
    background: transparent;
    border: 2px #a8a8a8 solid;
  }
  &.full-width {
    width: 100%;
    display: block;
  }
  &.navy-btn {
    background: #334958;

  }
  &.white {
    border: 1px solid #334958;
    background: none;
    font-size: 15px;
    color: #334958;
    text-align: center;
    padding-top: 14.5px;
    padding-bottom: 14.5px;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 600px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: 450px) {
      font-size: 14px;
    }
  }

  &[disabled="disabled"],
  &[disabled=""] {
    pointer-events: none;
    background: #ccc;
  }

  .counter {
    font-size: 11px;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    background: rgba(#000000, 0.17);
    display: inline-block;
    line-height: 19px;
    margin: 0 3px;
    &[data-counter="0"] {
      display: none;
    }
  }
  &.center-btn {
    margin-left: auto;
    margin-right: auto;
  }
  &.btn-sm {
    padding:5px 8px;
  }
}

.action-wrap {
  .delete-offer-btn {
    display: block;
    text-align: center;
    padding: 10px 0 0;
    text-decoration: underline;
    color: #333;
  }
}


.btn_new{
  display: flex;
  width: fit-content;
  margin: 0 ;
  background-color: #F4F6F6;
  border-radius: 4px;
  color: #1A1F23;
  border: none;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  @media (max-width: 900px) {
      width: 100%;
      padding: 20px 24px;
      margin-top: 20px;
      
  }
  &:after{
      margin-left: 10px;
      background-color: #1A1F23;
      border-radius: 50%;
      display: block;
      background-image: url("../img/ico_arrow_left.svg");
      content: ' ';
      background-size: 12px 12px;
      background-position: center;
      background-repeat: no-repeat;
      height: 24px;
      width: 24px;
  }
  &.navy{
    background-color: #1A1F23;
    color: #fff;
    &:after{
        margin-left: 10px;
        background-color: #00AF0D;
        border-radius: 50%;
        display: block;
        background-image: url("../img/ico_arrow_left.svg");
        content: ' ';
        background-size: 12px 12px;
        background-position: center;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
    }
  }
  &.green{
    background-color: #00AF0D;
    color: #fff;
    &:after{
        margin-left: 10px;
        background-color: #1A1F23;
        border-radius: 50%;
        display: block;
        background-image: url("../img/ico_arrow_left.svg");
        content: ' ';
        background-size: 12px 12px;
        background-position: center;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
    }
  }
  
}

.search_btn{
  display: flex;
  width: 20%;
  margin: 0 10px;
  background-color: #1A1F23;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
      width: 100%;
      padding: 20px 24px;
      margin-top: 20px;
      
  }
  &:after{
      margin-left: 10px;
      background-color: #00AF0D;
      border-radius: 50%;
      display: block;
      background-image: url("../img/ico_arrow_left.svg");
      content: ' ';
      background-size: 12px 12px;
      background-position: center;
      background-repeat: no-repeat;
      height: 26px;
      width: 26px;
  }
}

.btn_quote_next{
  display: flex;
  margin: 0 10px;
  background-color: #1A1F23;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 10px 30px;
  align-items: center;
  justify-content: space-between;
  @media(max-width:700px) { 
    width: 100%;
  }
  &:after{
      
      background-color: #00AF0D;
      border-radius: 50%;
      display: block;
      background-image: url("../img/ico_arrow_left.svg");
      content: ' ';
      background-size: 11px 13px;
      background-position: center;
      background-repeat: no-repeat;
      height: 28px;
      width: 30px;
    }
  .btn_quote_next_inner{
    border: none;
    padding: 0 20px 0 0;
    background: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px; 
  }
}

.btn_plain{
  display: flex;
  margin: 0 10px;
  width: fit-content;
  border-radius: 4px;
  @extend .color-navy;
  font-weight: bold;
  border: none;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  &:after{
      margin-left: 2rem;
      background-color: #00AF0D;
      border-radius: 50%;
      display: block;
      background-image: url("../img/ico_arrow_left.svg");
      content: ' ';
      background-size: 12px 12px;
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      width: 40px;
  }
}

.btn_plain_left{
  display: flex;
  width: fit-content;
  border-radius: 4px;
  @extend .color-navy;
  font-weight: bold;
  border: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  &:before{
    margin-right: 1rem;
    background-color: #00AF0D;
    border-radius: 50%;
    display: block;
    background-image: url(../img/ico_arrow_left.svg);
    content: ' ';
    background-size: 7px 10px;
    background-position: center;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    transform: scaleX(-1);
  }
}

.view_details {
  cursor: pointer;
  display: flex;
  width: 100%;
  margin: 0;
  background-color: #00af0d;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 15px;

  &:hover{
    opacity: 1;
  }
  &.view_details_second{
    background: #334958;
    justify-content: space-between;
    width: 100%;
    &:after{
      margin-left: 10px;
      background-color: #00AF0D;
      border-radius: 50%;
      display: block;
      background-image: url("../img/ico_arrow_left.svg");
      content: ' ';
      background-size: 12px 12px;
      background-position: center;
      background-repeat: no-repeat;
      height: 24px;
      width: 24px;
  }
    &:hover{
      color: #fff;
    }
  }
}

.navbuttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  .btn_plain{
      margin: 0 ;
      width: 5rem;
      padding: 0;
      &::after{
          margin-left: .3rem;
      }
      &:first-child{
          transform: scale(-1, 1);
      }
      &.slick-disabled{
          opacity: 0.25;
      }
  }
}

.width_full{
  width: 100%;
}