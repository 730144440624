html {
  box-sizing: border-box;
}

body {
  font-family: $standard-font-family;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f6f7fa;
  color: #939393;
  &.menu-active{
    @media (max-width: 650px) {
      overflow: hidden;
    }
    
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight: 700;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.page-mid {
}

.center-txt {
  text-align: center;
}

.left-txt {
  text-align: left;
}

.loading {
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  position: relative;
  overflow: hidden;
}

#page-wrap {
  position: relative;
  padding-top:120px;
  @media(max-width:800px) { 
    padding-top: 110px; 
  }
  @media(max-width:700px) { 
    padding-top: 90px; 
  }
  &.home-wrapper{
    padding-top: 102px
  }
}
.page-wrap-overlay {
  background-color: #1a1b1ad9;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
}

.default.inner-wrap {
  margin: 35px auto 65px;
  max-width: 600px;
  h1 {
    line-height: 1.2;
    font-style: normal;
    font-weight: 700;
    color: #334958;
    font-size: 44px;
    margin-bottom: 25px;
    @media (max-width: 900px) {
      font-size: 36px;
    }
    @media (max-width: 600px) {
      font-size: 32px;
    }
    @media (max-width: 450px) {
      font-size: 28px;
    }
  }
  .container {
    padding: 0;
  }

  p {
    margin-bottom: 1.5em;
  }
}

.bannerimagecopyright {
  color: #fff;
  font-size: 11px;
  opacity: 0.6;
  bottom: -71px;
  position: absolute;
}

#loader {
  img {
    margin-bottom: 15px;
  }
  p {
    background: #f7f5d6;
    border: 1px solid #dad9bd;
    padding: 20px;
    margin-bottom: 2.25em;
  }
}
