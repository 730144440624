.fancybox-bg {
  background-color: #fff;
  opacity: 0.33;
}

.fancybox-close-small {
  padding: 17px;
  height: auto;
  width: auto;
  cursor: pointer;
  i {
    color: white;
    font-size: 27px;
  }
  &:active {
    top: 1px;
  }
}

.frm-dialog.fancybox-content {
  padding: 0;
  max-width: 657px;
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.28);
  .fancybox-close-small {
    padding: 30px 20px;
    @media (max-width: 900px) {
      //font-size: 22px;
      padding: 25px 20px;
    }
    @media (max-width: 600px) {
      //font-size: 20px;
      padding: 20px 20px;
    }
    @media (max-width: 450px) {
      //font-size: 18px;
      padding: 15px 20px;
    }
  }

  .top {
    border-bottom: 1px #d7d7d7 solid;
    h2 {
      color: #fff;
      font-size: 20px;
      line-height: 1;
      font-family: $standard-font-family;
      font-style: normal;
      font-weight: $medium;
      padding: 34px 40px;
      text-align: left;
      position: relative;
      background-color: #334958;

      @media (max-width: 900px) {
        //font-size: 22px;
        padding: 29px 40px;
      }
      @media (max-width: 600px) {
        //font-size: 20px;
        padding: 25px 40px;
      }
      @media (max-width: 450px) {
        //font-size: 18px;
        padding: 21px 25px;
      }
    }
    h3 {
      background-color: #4e9ed7;
      font-family: $standard-font-family;
      font-weight: 700;
      font-size: 20px;
      color: #ffffff;
      line-height: 1.5;
      padding: 20px;
      @media (max-width: 900px) {
        font-size: 19px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
      }
      @media (max-width: 450px) {
        font-size: 17px;
      }
    }
    .back-btn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 17px;
      cursor: pointer;
      i {
        color: #fff;
        font-size: 27px;
      }
      &:active {
        top: 1px;
      }
    }
    &.sub-title-header {
      h2 {
        text-align: left;
        font-size: 17px;
        color: #ffffff;
        padding-left: 40px;
        padding-right: 40px;
      }
      h3 {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
  .dialog-item-inner {
    min-height: 200px;
    background-image: url("/assets/themes/reflex/img/loading_search.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
    ul {
      margin: 0;
      padding: 0;
      li {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        border-top: 1px #d7d7d7 solid;
        font-family: $standard-font-family;
        padding: 20px 40px;
        position: relative;
        cursor: pointer;
        &:nth-child(1) {
          border-top: 0;
        }
        &:active {
          top: 1px;
        }
        @media (max-width: 600px) {
          font-size: 17px;
          padding: 15px 30px;
        }
        @media (max-width: 450px) {
          font-size: 16px;
          padding: 12px 25px;
        }
      }
    }
  }
}

.general-dialog-txt {
  padding: 25px;
  background-color: #fff;
  @media (max-width: 600px) {
    padding: 20px;
  }
  @media (max-width: 450px) {
    padding: 15px;
  }
  p {
    margin-bottom: 1.5em;
    font-size: 12px;
    font-weight: $regular;
    color: #444;
    font-family: $standard-font-family;
    b{
      font-weight: $medium;
      font-size: 14px;
    }
  }
  ul{
    margin-bottom: 1.5em;
    li{
      margin-bottom: 1em !important;
      font-size: 12px !important;
      font-weight: $regular !important;
      list-style: disc !important;
      padding: 0 !important;
      margin-left: 18px !important;
      color: #444 !important;
      border-top: none !important;
      font-family: $standard-font-family;
    }
  }
}
