.comingsoom-main-area {
  padding: 60px 0 60px 0;
  .dealerlogos {
    margin-bottom: 30px;
  }
}

.comingsoon-categories {
  h2 {
    font-size: 23px;
    color: #334958;
    margin-bottom: 40px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 50px;
    .item {
      margin-bottom: 24px;
      margin-left: 12px;
      margin-right: 12px;
      width: calc(33.33% - 24px);
      background: #fff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
      @media (max-width: 760px) {
        width: calc(50% - 24px);
      }
      @media (max-width: 550px) {
        width: calc(100% - 24px);
      }
      .imgwrap {
        img {
          width: 100%;
        }
      }
      .txtarea {
        text-align: center;
        padding: 30px 0;
        h4 {
          font-size: 16px;
          font-weight: $semi-bold;
          color: #334958;
          width: 100%;
        }
      }
      &.click-through-arrow {
        padding: 20px;
        color: #334958;
        font-size: 14px;
        font-weight: 500;
        background-image: url(../img/ico_arrow.svg);
        background-repeat: no-repeat;
        background-position: right 20px center;
        position: relative;
        &:active {
          top: 1px;
        }
      }
    }
  }
}

.comingsoon-category-products {
  h2 {
    font-size: 23px;
    color: #334958;
    margin-bottom: 40px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 50px;
    .item {
      margin-bottom: 24px;
      margin-left: 12px;
      margin-right: 12px;
      width: calc(25% - 24px);
      background: #fff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
      @media (max-width: 960px) {
        width: calc(50% - 24px);
      }
      @media (max-width: 550px) {
        width: calc(100% - 24px);
      }
      .imgwrap {
        img {
          width: 100%;
        }
      }
      .txtarea {
        text-align: center;
        padding: 20px 0;
        h4 {
          font-size: 16px;
          font-weight: $semi-bold;
          color: #334958;
          width: 100%;
        }
      }
    }
  }
}

.comingsoon-enquiry {
  padding: 80px 0;
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 50px;
    @media (max-width: 822px) {
      flex-direction: column;
      flex-wrap: nowrap;
      margin-left: 0;
      margin-right: 0;
    }
    .col {
      align-items: flex-start;
      width: calc(50% - 24px);
      @media (max-width: 822px) {
        width: 100%;
        display: contents;
      }
      .textarea {
        padding-right: 30px;
        @media (max-width: 1220px) {
          padding-right: 10%;
        }
        @media (max-width: 822px) {
          padding-right: 0;
        }
        h2 {
          font-size: 56px;
          font-weight: $regular;
          margin-bottom: 30px;
          padding-right: 5px;
          @media (max-width: 1000px) {
            padding-right: 0;
            margin-bottom: 27px;
          }
          @media (max-width: 700px) {
            padding-right: 0;
            font-size: 23px;
            font-weight: $medium;
          }
        }

        div {
          p {
            line-height: 27px;
            font-weight: $regular;
            font-size: 15px;
            margin-bottom: 30px;
          }
          p:first-child {
            font-weight: $medium;
          }
        }
      }
      .comingsoon-form {
        margin: 12px auto;
        input {
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
          margin-bottom: 30px;
        }
        textarea {
          min-height: 196px;
          margin-bottom: 30px;
        }
        button {
          background: $green;
          color: #fff;
          border-radius: 4px;
          border: none;
          padding: 10px;
        }
      }
    }
  }
}
