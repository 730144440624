.dealerlogos {
  margin-bottom: 80px;
  .dealerlogos-top {
    display: flex;
    .dealerlogos-top-controls {
      flex-grow: 1;
      text-align: right;
      @media (max-width: 600px) {
        display: none;
      }
      a {
        margin-left: 5px;
        &.btn {
          padding: 7px 14px;
        }
      }
    }
  }
  h3 {
    font-size: 23px;
    color: #334958;
    margin-bottom: 40px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #e4e4e4;
    li {
      background: #fff;
      width: 14.285%;
      padding: 20px;
      min-height: 133px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 133px;
      text-align: center;
      line-height: 90px;
      border: 1px solid #e4e4e4;
      border-left: 0;
      img {
        margin: 0 auto;
        display: inline;
        max-height: 90px;
      }
    }
    &.responsive {
      li {
        @media (max-width: 1080px) {
          width: calc(100% / 6);
        }
        @media (max-width: 960px) {
          width: 20%;
        }
        @media (max-width: 850px) {
          width: 25%;
        }
        @media (max-width: 650px) {
          width: calc(100% / 3);
        }
        @media (max-width: 450px) {
          width: 50%;
        }
        @media (max-width: 325px) {
          width: 100%;
        }
      }
    }
  }
}
