
/* How it works inner columns */
.how_it_works{
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 6rem 3rem;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.15);
    border-radius: 4px;
    margin: 0 -20px;
    @media (max-width: 1000px) {
        margin: 0 ;
      }
    .item{
        background-image: url("../img/bck_num.svg");
        background-repeat: no-repeat;
        background-position: left 0.2rem;
        width: calc(33% - 40px);
        margin: 0 20px;
        @media (max-width: 1000px) {
            width: calc(100% - 40px);
            display: flex;
            flex-direction: row;
            background-size: 16%;
            padding-bottom: 4rem;
        }
        @media (max-width: 600px) {
            background-size: 23%;
        }
        .number{
            display: none;
            @extend .title-font;
            @media (max-width: 1000px) {
                display: block;
                width: 17%;
                font-size: 8vw;
                padding: 0 0 0.5rem 1.5rem;
                margin-bottom: 0;
                color: #fff;
                line-height: 1.1;
            }
            @media (max-width: 800px) {
                font-size: 9vw;
            }
        }
        .text-wrap{
            @media (max-width: 1000px) {
                width: 83%;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                padding-left: 3rem;
                padding-top: 0.5rem;
                align-items: flex-start;
            }
            h4{
                @extend .title-font;
                font-size: 3.3rem;
                padding:0 0 0.5rem 0 ;
                margin-bottom: 1rem;

                @media (max-width: 1000px) {
                    font-size: 2.4rem;
                    margin-bottom: 0;
                    line-height: 1;
                }
                
                span{
                    color: #fff;
                    padding: 0 2rem 0 3.7rem;
                    @media (max-width: 1000px) {
                        display: none;
                    }
                }
            }
            p{
    
            }
        }
        &:last-child{
            padding-bottom: 0;
        }

    }
}



/* How home wrapper */
.how_it_works_home{
    margin-top: -12rem;
    margin-bottom: 6rem;
    z-index: 10;
    position: relative;
}

/* How sub wrapper */
.how_it_works_sub{
    .bck_img{
        background-image: url("../img/bck_how_it_works.jpg");
        background-size: cover;
        text-align: center;
        padding: 8rem 0 9rem 0;
        margin-top: 11rem;
        h3{
            padding: 6rem 0;
            @extend .title-font;
            @extend .font-lg;
            color: #fff;
        }
     
    }
    .inner-wrap{
        top: -12rem;
        position: relative;
    }
}