/*
  Compontent Imports
*/

@import "icons";
@import "typography";
@import "buttons";
@import "slideshow";
@import "mobilemenu";
@import "forms";
@import "tables";
@import "gallery";
@import "maps";
@import "products";
@import "tabs";
@import "dialogs";
@import "reviews";
@import "faqs";
@import "video";
@import "message";
@import "notifications";
@import "search";
@import "dealerlogos";
@import "adverts";
@import "basket";
@import "checkout";
@import "pagination";
@import "home-banner";
@import "how_it_works";
@import "why_us";
@import "latest_news";
@import "testimonials";
@import "page_loader";