section.regular {
  position: relative;
}

.inner-wrap {
  max-width: $container + px;
  width: 90%;
  margin: auto;
}

.inner-wrap-sm {
  max-width: 900px;
  width: 90%;
  margin: auto;
}

.page-mid {
  background: #f6f7fa;
  padding: 60px 0;
  &.homepage-mid {
    background: #fff;
  }
  &.hr {
    border-top: 2px #e6e7e9 solid;
  }
  &.manufacturer_area{
    padding: 30px 0;
  }
  @media (max-width: 900px) {
    padding: 50px 0;
  }
  @media (max-width: 600px) {
    padding: 40px 0;
  }
  @media (max-width: 450px) {
    padding: 30px 0;
  }
  &.remove-padding {
    padding: 0;
    @media (max-width: 900px) {
      padding: 0;
    }
    @media (max-width: 600px) {
      padding: 0;
    }
    @media (max-width: 450px) {
      padding: 0;
    }
  }
  h2 {
    font-size: 25px;
    color: #334958;
    line-height: 1.35;
    @media (max-width: 900px) {
      font-size: 22px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
    }
    @media (max-width: 450px) {
      font-size: 18px;
    }
    &.manu_intro{
      font-size: 38px;
      font-weight: 600;
      width: 70%;
      line-height: 1.5;
      margin-bottom: 20px;
      @media (max-width: 900px) {
        font-size: 30px;
        width: 100%;
      }
      @media (max-width: 600px) {
        font-size: 24px;
      }
      span{
        color: #00AF0D;
      }
    }
    &.manu_intro_2{
      font-size: 30px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        font-size: 24px;
      }
      span{
        color: #00AF0D;
      }
    }
  }
  .row-col-wrap {
    padding: 60px 0;
    border-top: 1px #e6e7e9 solid;
    @media (max-width: 900px) {
      padding: 50px 0;
    }
    @media (max-width: 600px) {
      padding: 40px 0;
    }
    @media (max-width: 450px) {
      padding: 30px 0;
    }
    .inner-wrap {
      display: flex;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      .col {
        padding: 0;
        width: 50%;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          flex-direction: column;
          width: 100%;
          flex-basis: auto;
        }
        h2 {
          font-size: 30px;
          color: #334958;
          line-height: 1.5;

          @media (max-width: 900px) {
            font-size: 26px;
          }
          @media (max-width: 600px) {
            font-size: 22px;
          }
          @media (max-width: 450px) {
            font-size: 20px;
          }
        }
        p {
          margin: 10px 0 20px;
          font-size: 15px;
          line-height: 1.7;
        }
        a.btn {
        }
      }
    }
    &:nth-child(1) {
      border-top: 0;
    }
    &:nth-child(odd) {
      .col:nth-child(1) {
        padding-right: 40px;
        @media (max-width: 600px) {
          padding-right: 0;
        }
      }
      .col:nth-child(2) {
        padding-left: 40px;
        @media (max-width: 600px) {
          padding-left: 0;
          padding-top: 20px;
        }
      }
    }
    &:nth-child(even) {
      .inner-wrap {
        .col:nth-child(1) {
          order: 3;
          padding-left: 40px;
          padding-right: 0;
          @media (max-width: 600px) {
            padding-left: 0;
            order: 0;
          }
        }
        .col:nth-child(2) {
          padding-left: 0;
          padding-right: 40px;
          @media (max-width: 600px) {
            padding-right: 0;
            padding-top: 20px;
          }
        }
      }
    }
  }
}

.minor-wrap {
  max-width: 470px;
  margin: 0 auto;
  .btn-wrap {
    display: flex;
    flex-direction: column;
    span {
      text-align: center;
      display: block;
      margin: 10px 0;
    }
  }
}

ul.listBrands {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
  li {
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    width: 100%;
    max-width: 180px;
    position: relative;
    margin: 10px;
    width: calc(25% - 21px);
    cursor: pointer;
    height: 140px;
    img {
      max-width: 85%;
    }
    @media (max-width: 600px) {
      width: calc(50% - 20px);
      max-width: none;
      margin: 10px;
      img {
        max-height: 50px;
      }
    }
    @media (max-width: 400px) {
      width: calc(100% - 20px);
      max-width: none;
      margin: 10px;
      img {
        max-height: 50px;
      }
    }
    div.check_wrap_item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: hidden;
      border: 20px solid #fff;
      .icheckbox_flat-blue {
        display: none;
      }
      &.selected {
        border: 2px #00AF0D solid;
        &:after {
          background: #00AF0D;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          top: -15px;
          right: -15px;
          content: "\f00c";
          font-family: FontAwesome;
          color: #fff;
          font-size: 18px;
          line-height: 38px;
          text-align: center;
        }
      }
    }
  }
}

.two-col-grid {
  display: flex;
  .col {
    margin: 0;
    padding: 0;
    &:nth-child(1) {
    }
    &:nth-child(2) {
      padding-left: 60px;
    }
  }
}


.padding-t-4{
  padding-top: 40px;
}

.padding-b-4{
  padding-bottom: 40px;
}