.search-frm-dialog {
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  max-width: 713px;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  form {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: calc(100% - 75px);
    position: relative;

    input {
      box-shadow: none;
      background: none;
      margin: 0;
      padding: 36.5px 24px;
      font-size: 20px;
      font-weight: $regular;
      font-family: $standard-font-family;
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        display: block;
      }
    }
  }
  button.fancybox-button {
    display: inline-block;
    background: none;
    padding: 33px 20px;
    i {
      color: #334958;
    }
  }
  .fancybox-close-small {
    position: static;
    top: auto;
    right: auto;
    display: inline-block;
    background: none;
    i {
      color: #334958;
    }
  }
  
}

.search-frm-home {
  background: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.10);
  border-radius: 4px;
  margin: 0 auto;
  padding: 0;
  width: 190px;
  margin-right: 10px;
  @media (max-width: 1075px) {
    display: none;
  }
  form {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    position: relative;
    input {
      box-shadow: none;
      background: none;
      margin: 0;
      padding: 12px;
      font-size: 12px;
      font-weight: $regular;
      font-family: $standard-font-family;
      width: 90%;
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 19px;
        display: block;
        margin-right: 10px;
      }
    }
  }
}


.search_mobile_btn{
  display: none;
  margin-right: 17px;
  cursor: pointer;
  @media (max-width: 1075px) {
    display: block;
  }
}

.search_mobile{
  background-color: $green;
  height: 200px;  
  position: fixed;
  top: -200px;
  left: 0;  
  width: 100vw;
  z-index: 1000;
  padding: 30px;
  transition: all 0.2s ease-in-out;
  &.active{
    top: 0;
  }
  .titlearea{
    display: flex;
    justify-content: space-between;
    h4{
      color: #fff;
    }
    .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
      background-color: #ffffff;
    }
  }

  .search-frm-mobile {
    background: #ffffff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    margin: 0 auto;
    padding: 0;
    margin-top: 30px;
    margin-right: 10px;
    form {
      margin: 0;
      padding: 0;
      display: inline-block;
      width: 100%;
      position: relative;
      input {
        box-shadow: none;
        background: none;
        margin: 0;
        padding: 12px;
        font-size: 12px;
        font-weight: $regular;
        font-family: $standard-font-family;
        width: 90%;
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 19px;
          display: block;
          margin-right: 10px;
        }
      }
    }
  }

}

