.homepage-banner {
    height: 98vh ;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 600px;
    @media (max-width: 900px) {
        height: 100vh ;
    }
    @media (max-width: 600px) {
        justify-content: flex-start;
    }
    
    .banner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 960px;
        width: 90%;
        margin-top: -8vw;
        z-index: 10;
        @media (max-height: 830px) {
            margin-top: -10vw;
        }
        @media (max-height: 630px) {
            margin-top: -6vw;
        }
        @media (max-height: 530px) {
            margin-top: 0;
        }
        @media (max-width: 600px) {
            margin-top: 10vw;
        }
        .banner-text{
            width:70%;

            @media (max-width: 900px) {
                width:100%;
            }
            h1 {
                font-size: 60px;
                line-height: 1.2;
                font-style: normal;
                font-weight: $medium;
                color: #1A1F23;
                font-family: bitter, serif;
                font-weight: 700;
                font-style: normal;
                @media (max-height: 500px) {
                    font-size: 40px;
                }
                @media (max-width: 1100px) {
                    font-size: 5.6vw;
                }
                @media (max-width: 600px) {
                    font-size: 9vw;
                }
              }
              p {

                max-width: 530px;
                color:#555758;
                font-weight: 500;
                font-size: 18px;
                width:100%;
                margin: 25px auto 35px;
                @media (max-width: 900px) {
                    width:100%;
                }
                @media (max-height: 830px) {
                    margin: 15px auto 25px;
                }
              }
        }
    }
    &.subpagebanner{
        max-height: 100vh;
        height: 550px;
        top: -50px;
        margin-bottom: -50px;
        @media (max-width: 1000px) {
            height: -webkit-fill-available;
            max-height: none;
            padding-bottom: 10vw;
        }
        &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-height: 100vh;
            height: 100%;
            background: linear-gradient(1deg, rgba(26,31,35,0.5) 49%, rgba(0,0,0,0) 100%);
            @media (max-width: 1000px) {
                height: -webkit-fill-available;
                max-height: none;
            }
        }
        .banner-content{
            max-width: 1200px;
            margin-top: 0;
            @media (max-width: 1000px) {
                padding-top: 20vw;
            }
            
            .banner-text{
                width: 100%;
                h1{
                    color: #fff;
                    font-size: 50px;
                }
                p {
                    color: #fff;
                    font-size: 16px;
                    opacity: .8;
                    width: 70%;
                    @media (max-width: 1000px) {
                        width: 100%;
                    }
                }
            }
            .banner-search {
                width: 80%;
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }
        }
    }
}


.banner-search{
    background: #FFFFFF;
    box-shadow: 0 2px 40px 0 rgba(0,0,0,0.30);
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    display: flex;
    font-size: 18px;
    @media (max-height: 830px) {
        padding: 10px;
    }
    form{
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0 ;
        position: relative;
        @media (max-width: 900px) {
            flex-direction: column;
            margin: 0 -10px ;
            
        }
        .form-wrap{
            width: 40%;
            position: relative;
            @media (max-width: 900px) {
                width:100%;
            }
            &:after {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f107";
                color: #1a1f23;
                position: absolute;
                bottom: 16px;
                right: 10px;
                font-size: 23px;
                width: 33px;
                background-color: #fff;
                pointer-events: none;
            }
            select{
                width: 94%;
                border: none;
                //background-color: #f3f3f3;
                background-color: #ffffff;
                padding: 20px;
                color: #1A1F23;
                margin: 0 10px;
                //border-radius: 4px;
                -webkit-appearance:none;
                position: relative;
                @media (max-width: 900px) {
                    border-bottom: 1px solid #D4DBDD;
                    font-size: 90%;
                }
                &:nth-child(2){
                    border-left: 1px solid #D4DBDD;
                    position: relative;
                    float: left;
                    @media (max-width: 900px) {
                        border-left: none;
                        border-bottom: none;
                    }
                    
                }



            } 
            .loading-search{
                position: absolute;
                top: 0;
                left: 28px;
                width: 80%;
                height: 64px;
                background-color: #fff;
                display: none;
                align-items: center;
                justify-content: flex-start;
                &.show{
                    display: flex;
                    z-index: 100;
                }
                img{
                    width: 30px;
                }
            }
        }

        .search_btn {
            margin: 0 0 0 10px;
        }
        
    }
}

