.menu_active{
    position: fixed;
  
    .mobile_menu{
        display: block;
    }
    .main-product-menu {
        .menu_trigger{
            display: block;
        }
    }
}








.mobile_menu{
    position: fixed;
    left: auto;
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    overflow: scroll;
    top: 110px;
    bottom: 0px;
    height: 100%;
    right: 0px;
    width: 376px;
    display: none;
    padding: 10vw;
    width: 100vw;
    z-index: 98;
    @media (max-width: 600px) {
        top: 90px;
    }
    .menu_inner{

        ul.parents {
            display: flex;
            flex-wrap: wrap;
            padding-left: 287px;
            flex-direction: column;
            position: relative;
            @media (max-width: 1120px) {
            padding-left: 0;
            }
            li {
            .parent_link {
                margin: 0;
                color:  #1A1F23;
                font-weight: 500;
                font-size: 11px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                line-height: 1.2;
                color: #1a1f23;
                font-family: bitter, serif;
                font-weight: 700;
                width: 300px;
                border-radius: 4px;
                margin: 0 0 1px 0;
                display: flex;
                flex-direction: row;
                padding: 8px 12px;
                justify-content: space-between;
                align-items: center;
                height: fit-content;
                text-decoration: none;
                &:hover{
                background-color: #eeeeee;
                cursor: pointer;
                text-decoration: none;
                &:after{
                    opacity: 1;
                }
                }
                @media (max-width: 800px) {
                width: 100%;
                }
                &:after{
                margin-left: 10px;
                border-radius: 50%;
                display: block;
                background-image: url("../img/ico_arrow_left_dark.svg");
                content: ' ';
                background-size: 12px 12px;
                background-position: center;
                background-repeat: no-repeat;
                height: 26px;
                width: 26px;
                opacity: 0;
                }
                &.buynow {
                font-size: 26px;
                justify-content: flex-start;
                padding-top: 20px;
                padding-bottom: 20px;
                &:after{
                    margin-left: 10px;
                    border-radius: 50%;
                    display: block;
                    background-image: url("../img/ico_arrow_left.svg");
                    background-color: #00AF0D;
                    content: ' ';
                    background-size: 12px 12px;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 20px;
                    opacity: 1;
                }
                &:hover{
                    background-color: #fff;
                    a{
                    text-decoration: underline;
                    }
                }
                
                }
            }

            ul.submenu {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                padding-left: 0;
                position: absolute;
                top: 0;
                left: 620px;
                width: 30%;
                opacity: 0;
                pointer-events: none;
                @media (max-width: 1120px) {
                left: 50%;
                width: 50%;
                }
                @media (max-width: 800px) {
                width: 100%;
                position: relative;
                left: auto;
                top: auto;
                height: 0;
                }
                li {
                width: fit-content;
                display: flex;
                padding: 10px 0 5px 0;
                margin-bottom: 10px;
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 2px solid #fff;
                &:hover, .active{
                    border-bottom: 2px solid #00AF0D;
                    cursor: pointer;
                    text-decoration: none;
                    a{
                    text-decoration: none;
                    color: #1a1f23;
                    }
                    &:after{
                    opacity: 1;
                    }
                }
        
                a {
                    margin: 0;
                    font-weight: 500;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.3rem;
                    line-height: 0.8;
                    color: #4e4e4e;
                    &.active,
                    &:hover {
                    }
                    &.parent_category_link{
                    font-size: 16px;
                    line-height: 1.2;
                    color: #1a1f23;
                    font-family: bitter, serif;

                    }
                }
                &.parent_link_outter{
        
                }
                
                }
            }
            &.active{
                .submenu{
                opacity: 1;
                pointer-events: inherit;
                @media (max-width: 800px) {
                    height: auto;
                    padding-left: 12px;
                }
                }

                a.parent_link {
                    background-color: #eeeeee;
                    cursor: pointer;
                        text-decoration: none;
                        &:after{
                            opacity: 1;
                            background-image: url("../img/ico_arrow_left.svg");
                            background-color: #00AF0D;
                        }
                        &.buynow {
                            background-color: #fff;
                            text-decoration: underline;
                            &:after{
                                background-image: url("../img/ico_arrow_left.svg");
                            }
                        }
                    }
                }
            }
        }
    }
}