.reviews-wrap {
  display: flex;
  @media (max-width: 960px) {
    flex-wrap: wrap;
  }
  .reviews-col {
    width: calc(100% - 415px);
    @media (max-width: 960px) {
      width: 100%;
    }
    &:nth-child(1) {
      padding-right: 80px;
      @media (max-width: 1150px) {
        padding-right: 40px;
      }
      @media (max-width: 960px) {
        padding-right: 0;
        margin-bottom: 15px;
      }
    }
    .validation-msg {
      max-width: none;
    }
    .comment {
      margin-bottom: 25px;
      .comment-body {
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
        .comment-text {
          padding: 40px 20px;
          font-size: 14px;
          color: #939393;
          line-height: 1.5;
          p {
            margin-top: 1.5em;
            &:nth-child(1) {
              margin-top: 0;
            }
          }
        }
        span.lbl {
          display: block;
          font-size: 12px;
          color: #334958;
          font-weight: 700;
        }
        span.question {
          display: block;
          font-weight: 600;
          font-size: 15px;
          color: #939393;
          line-height: 1.5;
          margin-bottom: 20px;
          margin-top: 3px;
        }
        .comment-footer {
          padding: 20px 20px;
          display: flex;
          border-top: 1px #e8e9ec solid;
          h5 {
            font-weight: 600;
            font-size: 11px;
            color: #334958;
          }
          .comment-footer-col {
            &:nth-child(1) {
              flex-grow: 1;
            }
          }
          @media (max-width: 1150px) {
            flex-direction: row;
            flex-wrap: wrap;
            .comment-footer-col {
              width: 100%;
              flex-grow: 0;
              &:nth-child(1) {
                flex-grow: 0;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .reviews-frm {
    width: 415px;
    @media (max-width: 960px) {
      width: 100%;
    }
    .row {
      margin: 0;
      padding: 0;
    }
    .review-overview {
      background: #4e9ed7;
      color: #fff;
      margin-bottom: 25px;
      padding: 15px;
      label {
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .rating-stars {
        margin-bottom: 10px;
        i {
          font-size: 28px;
          margin-right: 4px;
          &:active {
            top: 1px;
          }
        }
      }
    }
    form.row {
      margin-top: 10px;
    }
    .rating-stars-select-wrap {
      margin-bottom: 20px;
      i.rating-stars-select {
        cursor: pointer;
        position: relative;
        &:active {
          top: 1px;
        }
      }
    }
    h4 {
      font-size: 30px;
      color: #334958;
      margin: 0;
      @media (max-width: 600px) {
        font-size: 26px;
      }
      @media (max-width: 450px) {
        font-size: 22px;
      }
    }
    div {
      width: 100%;
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      background: #ffffff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
      border-radius: 4px;
      font-size: 11px;
    }
    button.btn {
      width: 100%;
      border-radius: 4px;
      max-width: none;
      font-size: 15px;
      font-weight: 600;
      border: none;
    }
  }
}

.rating-stars {
  i {
    &.filled {
      color: #f6ca51;
    }
  }
}

.placeholder-txt-wrap {
  text-align: 11px;
  padding: 25px 0;
  a {
    color: #334958;
    text-decoration: underline;
    font-weight: 600;
  }
}
