.extras-grid {
  display: flex;
  margin: 0 -10px 25px;
  flex-wrap: wrap;
  li {
    font-weight: 500;
    font-size: 11px;
    color: #5a5a5a;
    border: 1px solid #e8e9ec;
    display: flex;
    align-items: center;
    margin: 5px 10px;
    width: 100%;
    max-width: 202px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
      max-width: 100%;
    }
    .lbl {
      padding: 10px;
    }
    .status {
      background: #00AF0D;
      color: #ffffff;
      padding: 10px;
      width: 75px;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &.price {
        background: #284756;
      }
    }
  }
  &.selected {
    li {
      max-width: calc(50% - 20px);
      background: #f5f5f5;
      @media (max-width: 750px) {
        max-width: 100%;
      }
      .status {
        background: transparent;
        border-left: 1px #e8e9ec solid;
        color: #5a5a5a;
      }
    }
  }
}

.pagination {
  display: flex;
  margin: 35px auto;
  .column:nth-child(2) {
    flex-grow: 1;
  }
}

.iziToast {
  background: #4e9ed7;
  color: #fff;
  padding: 10px;
  .iziToast-body {
    padding-right: 45px;
    .iziToast-close {
      background: #15364e;
      position: relative;
      &:after {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f00d";
        position: absolute;
        left: 5px;
        top: 5px;
        color: #fff;
      }
    }
  }
}


.check-box-item.disabled {
  opacity:0.3;
}