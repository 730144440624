.panel {
  position: fixed;
  left: auto;
  right: -360px ; /*left or right and the width of your navigation panel*/
  width: 360px ; /*should match the above value*/

  background: #f1f1f1;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.28);
  z-index: 20000;
  overflow: scroll;
  .notifications_inner{
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .top {
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.28);
    padding: 20px;
    border-bottom: 1px #d4d4d4 solid;
    display: flex;
    width: 100%;
    z-index: 1000;
    overflow: hidden;
    h4 {
      font-weight: 600;
      font-size: 19px;
      width: 95%;
      color: #334958;
    }
    span {
      cursor: pointer;
      z-index: 2000;
      padding: 22px;
      transition: right 300ms ease 0s;
    }
  }
  .mid {
    padding: 15px 20px;
    padding-top: 85px;
    background: #f1f1f1;

    .loader {
      text-align: center;
    }

    ul {
      margin: 7px auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        margin: 7px 0;
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
        font-weight: 600;
        font-size: 12px;
        color: #5b5b5b;
        width: 100%;
        a {
          display: block;
          color: #5b5b5b;
          &:hover {
            text-decoration: none;
          }
          .mid-body {
            padding: 15px;
            display: block;
            span {
              img {
                margin-right: 10px;
              }
            }
          }
          .mid-btm {
            display: flex;
            align-items: center;
            border-top: 1px #e8e9ec solid;
            padding: 7.5px 12.5px;
            min-height: 49.5px;
            span.mid-btm-col {
              padding: 0;
              &:nth-child(1) {
                flex-grow: 1;
              }
              span.new-status {
                background: $green;
                border-radius: 0 15px 15px 0;
                color: #fff;
                display: inline-block;
                padding: 7.5px;
                position: relative;
                left: -12.5px;
              }
            }
          }
        }
      }
    }

    .btn-wrap {
      padding: 15px 0;
      .btn {
        width: 100%;
        display: block;
      }
    }
  }
}

#close-notifications-tab.open {
  right: 0;
}
