.list-dealers-for-manufacturer{
    h2{
        font-size: 24px;
        color: #334958;
        font-family: $standard-font-family;
        margin: 0 0 20px;
    }
    ul.dealers-for-manufacturer {
        margin-bottom: 50px;
        li {
            background: #FFFFFF;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.13);
            margin:15px 0;
            padding:20px;
            display: flex;
            @media (max-width: 750px) {
                flex-direction: column;
              }
            div:nth-child(1) {
                width: 270px;
                .dealer-detail{
                    span{
                        font-size: 12px;
                    }
                    h4{
                        font-weight: 800;
                        color: #334958;
                        font-size: 16px;
                    }
                    h5{
                        font-weight: 800;
                        color: #00AF0D;
                        font-size: 14px;
                    }
                }
            }
            div:nth-child(2) {
                width: calc(100% - 270px );
                display: flex;
  
            }
            

            .col {
                border-left: 1px solid #DBDBDB;
                padding-left:25px;
                margin-left: 25px;
                display: flex;
                flex-direction: column;
                span {
                    font-size: 12px;
                }
                h4{
                    color: #334958;
                    font-size: 20px;
                    font-weight: 600;
                    &.yes{
                        color: #00AF0D;
                    }
                }
            }
            img {
                max-width: 100%;
            }
        }
    }
}

.list-dealers {
    h2{
        font-size: 24px;
        color: #334958;
        font-family: $standard-font-family;
        margin: 0 0 20px;
    }
    ul.dealers-for-manufacturer {
        margin-bottom: 50px;
        li {
            background: #FFFFFF;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.13);
            margin:15px 0;
            padding:30px 20px;
            display: flex;
            @media (max-width: 840px) {
                flex-direction: column;
              }
            .dealer-name {
                width: 400px;
                .dealer-detail{
                    display: flex;
                    @media (max-width: 1100px) {
                        flex-direction: column;
                    }
                    @media (max-width: 840px) {
                        flex-direction: row;
                    }
                    .image{
                        width: 65px;
                        img{
                            width: 65px;
                        }
                    }
                    .text{
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        padding-left: 20px;
                        @media (max-width: 1100px) {
                            padding-left: 0;
                            padding-top: 10px;
                        }
                        @media (max-width: 840px) {
                            padding-left: 10px;
                            padding-top: 0;
                        }
                    }
                    span{
                        font-size: 12px;
                    }
                    h4{
                        font-weight: 800;
                        color: #334958;
                        font-size: 16px;
                    }
                    h5{
                        font-weight: 800;
                        color: #00AF0D;
                        font-size: 14px;
                    }
                    p{
                        font-size: 14px; 
                        font-weight: 800;
                        span{
                            
                        }
                    }
                }
            }
            .dealer-info {
                flex-grow: 1;
                display: flex;
                @media (max-width: 840px) {
                    flex-direction: column;
                    display: block;
                }
  
            }
            

            .col {
                border-left: 1px solid #DBDBDB;
                padding-left:25px;
                margin-left: 25px;
                display: flex;
                flex-direction: column;
                @media (max-width: 840px) {
                    border-left: none;
                    border-top: 1px solid #DBDBDB;
                    padding-left:0;
                    margin-left: 0;
                    padding-top:25px;
                    margin-top: 25px;
                }
                h4 {
                    font-size: 16px;
                    color: #334958;
                    font-weight: 600;
                }
                p{
                    font-size: 14px;
                    font-weight: 600;
                    span{
                        color: #00AF0D;
                        
                    }
                }
            }
            img {
                max-width: 100%;
            }
        }
    }
}

.invites{
    h2{
        margin: 0 0 60px;
    }
    .invites-content{
        display: flex;
        flex-direction: row;
        @media (max-width: 920px) {
            flex-direction: column;
        }

        h3{
            font-size: 20px;
            color: #334958;
            font-family: $standard-font-family;
            margin: 0 0 20px;
        }
    }
    .left{
        background-color:#ffffff ;
        width: 55% ;
        padding: 60px;
        @media (max-width: 920px) {
            width: 100% ;
            order: 2;
        }
        p, li{
            color:#5F5F5F ;
            font-size: 14px;
            margin-bottom: 20px;
        }
        ul{
            padding-left: 20px;
            li{
                list-style-type: decimal;
                ul{
                    
                    li{
                        list-style-type: none ;
                    }
                }
            }
        }
        .input{
            color: #00AF0D;
        }

    }
    .right{
        background-color:#334958 ;
        width: 45% ;
        color:#ffffff ;
        padding: 60px;
        @media (max-width: 920px) {
            width: 100% ;
            order: 1;
        }
        h2, h3{
            color:#fff;
            font-weight: 600;
        }
        h2{
            font-size: 28px;
            margin-bottom: 30px;
        }
        h3{
            font-size: 22px;
            margin-bottom: 20px;
        }
        p, li{
            color:#fff ;
            font-size: 14px;
            margin-bottom: 20px;
        }
        ul{
            padding-left: 16px;
            li{
                list-style-type: decimal;

            }
        }
        input{
            background-color: #fff;
            border: none;
            padding: 15px 30px;
            font-size: 14px;
            margin-bottom: 20px;
        }
        hr{
            margin: 50px 0;
        }
        button{
            margin-bottom: 20px;
        }
    }
}

.title_wrap{
    display: flex;
    .left{

    }
    .right{
        align-items: flex-end;
        display: flex;  
        flex-direction: column;
        flex-grow: 1;
    }
}

.notes{
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.13);
    padding: 30px;
    margin-bottom: 30px;
    h3{
        font-weight: 600;
        color: #334958;
        font-size: 20px;
    }
    .date{
        font-weight: 600;
        color: #00AF0D;
        font-size: 14px;
        margin-bottom: 20px;
    }
    p{
        color:#5F5F5F ;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .btn{
        font-size: 14px;
    }
}