.why_us{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -20px 8rem -20px;
    @media (max-width: 1000px) {
        margin: 0 ;
    }
    .item{
        width: calc(33% - 40px);
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        padding: 5rem 5rem 12rem 5rem;
        background-color:#00AF0D ;
        border-radius: 4px;
        align-items: flex-end;
        position: relative;
        transition: all .2s ease-in-out;
        @media (max-width: 1000px) {
            width: calc(100% - 40px);
            margin-bottom: 4rem;
            flex-direction: row;
            padding: 3rem 5rem 4rem 5rem;
            align-items: center;
        }
        @media (max-width: 600px) {
            padding: 2rem 3rem 3rem 3rem;
            margin-bottom: 2rem;
            flex-direction: column;
            align-items: flex-end;
        }
        img{
            height: 60px;
            width: auto;
            justify-self: flex-end;
            margin-bottom: 4rem;
            @media (max-width: 1300px) {
                margin-bottom: 2rem;
            }
            @media (max-width: 1000px) {
                margin-bottom: 0;
            }
        }
        .text-wrap{
            @media (max-width: 1000px) {
                padding-left: 5rem;
            }
            @media (max-width: 600px) {
                padding-left: 0;
                margin-top: -2rem;
            }
            h3{
                @extend .title-font;
                @extend .font-md;
                padding:0 0 0.5rem 0 ;
                margin-bottom: 1rem;
                color: #fff;
                width: 100%;
                text-align: left;
                font-size: 3rem;
                @media (max-width: 1370px) {
                    font-size: 2.45vw;
                }
                @media (max-width: 1000px) {
                    font-size: 2.4rem;
                }
                @media (max-width: 600px) {
                    margin-bottom: 0;
                }
            }
            p{
                color: #fff;
                width: 100%;
                text-align: left;
                @media (max-width: 600px) {
                    font-size: 15px;
                }
            }
        }

        &:after{
            margin-left: 10px;
            background-color: rgba(255,255,255,0.10);
            border-radius: 50%;
            display: block;
            background-image: url("../img/ico_arrow_left.svg");
            content: ' ';
            background-size: 16px 16px;
            background-position: center;
            background-repeat: no-repeat;
            height: 44px;
            width: 44px;
            position: absolute;
            bottom: 5rem;
            @media (max-width: 1000px) {
                display: none;
            }
        }

        &:nth-child(1){

        }
        &:nth-child(2){
            background-color:#008C0A ;
        }
        &:nth-child(3){
            background-color:#006907 ;
        }
        &:hover{
            text-decoration: none;
            transform: scale(1.04);
        }
    }
}