.homepage-top {
  max-height: 100vh;
  height: 500px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    height: 500px;
    background: linear-gradient(
      90deg,
      rgba(26, 31, 35, 0.72) 19%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .inner-wrap {
    z-index: 200;
    display: flex;
    align-items: center;
    height: 100%;
    .homepage-top-content {
      position: relative;
      z-index: 1000;
      .homepage-top-content-txt {
        .homepage-top-content-inner {
          max-width: 550px;
          &.extended {
            max-width: 600px;
          }
          h1 {
            font-size: 60px;
            line-height: 1.2;
            font-style: normal;
            font-weight: $medium;
            color: #fff;
            text-shadow: 0 0 6px rgba(0, 0, 0, 0.32);
            @media (max-width: 1200px) {
              font-size: 54px;
            }
            @media (max-width: 900px) {
              font-size: 50px;
            }
            @media (max-width: 600px) {
              font-size: 46px;
            }
            @media (max-width: 450px) {
              font-size: 38px;
            }
            @media (max-width: 380px) {
              font-size: 34px;
            }
          }
          p {
            margin: 15px 0 35px;
            max-width: 530px;
            color: #fff;
            text-shadow: 0 0 6px rgba(0, 0, 0, 0.32);
          }

          .btn-wrap {
            position: relative;
            i {
              margin-left: 5px;
            }
            .btn {
              margin-right: 10px;
              padding: 17px 32px;
              font-weight: $regular;
            }
            @media (max-width: 480px) {
              display: flex;
              flex-direction: column;
              .btn {
                margin-bottom: 10px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.homepage-mid {
  .row-col-wrap {
    .inner-wrap {
      .col {
        a.btn {
          line-height: 2;
        }
        @media (max-width: 220px) {
          a.btn {
            width: 100%;
            line-height: 1.5;
            display: block;
          }
        }
      }
    }
  }
}

.highlight-points {
  padding: 15px 0 50px;
  h3 {
    font-size: 30px;
    color: #334958;
    font-style: normal;
    font-weight: $medium;
    margin-bottom: 30px;
    @media (max-width: 900px) {
      font-size: 26px;
    }
    @media (max-width: 600px) {
      font-size: 22px;
    }
    @media (max-width: 450px) {
      font-size: 20px;
    }
  }
  .row {
    display: flex;
    margin: 0;
    @media (max-width: 900px) {
    }
    @media (max-width: 600px) {
    }
    @media (max-width: 450px) {
    }
    .col {
      width: 33.3333%;
      &:nth-child(2) {
        margin: 0 70px;
        @media (max-width: 1128px) {
          margin: 0 50px;
        }
      }
      @media (max-width: 600px) {
        width: 100%;
      }
      padding: 0;
      .icon {
        width: 76px;
        display: inline-block;
        vertical-align: top;
        @media (min-width: 600px) and (max-width: 1128px) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .txt {
        width: calc(100% - 81px);
        display: inline-block;
        vertical-align: top;
        padding: 0 0 0 15px;
        @media (min-width: 600px) and (max-width: 1128px) {
          width: 100%;
          padding: 0;
        }
        h4 {
          font-family: $title-font-family;
          font-size: 16px;
          color: #334958;
          font-style: normal;
          font-weight: $semi-bold;
        }
        p {
          font-family: $standard-font-family;
          font-size: 13px;
          color: #a7b8c4;
        }
      }

      @media (max-width: 380px) {
        display: flex;
        flex-direction: column;
        padding: 0;
        .icon {
          width: 100%;
          text-align: center;
          padding-bottom: 30px;
        }
        .txt {
          width: calc(100%);
          padding: 0;
        }
      }
    }
    @media (max-width: 600px) {
      flex-direction: column;
      flex-wrap: nowrap;
      .col {
        display: flex;
        flex-basis: auto;
        width: 100%;
        &:nth-child(2) {
          margin: 35px 0;
        }
      }
    }
  }
}

.homepage-btm {
  padding: 75px 0;
  .inner-wrap {
    .getstarted {
      display: flex;
      @media (max-width: 754px) {
        display: block;
      }
      .col {
        width: 50%;
        padding: 0;
        @media (max-width: 754px) {
          width: 100%;
          &:nth-child(2) {
            display: none;
          }
        }
        &:nth-child(1) {
          padding-right: 40px;
          display: flex;
          align-items: center;
        }
        h3 {
          font-size: 40px;
          color: #334958;
          line-height: 1.2;
          @media (max-width: 1000px) {
            font-size: 30px;
          }
        }
        p {
          line-height: 1.5;
          margin: 25px auto;
        }
        .btn-wrap {
          display: flex;
          .btn {
            margin-left: 10px;
            padding: 9px 25px;
            &:nth-child(1) {
              margin-left: 0;
            }
            @media (max-width: 775px) {
              padding: 9px 15px;
            }
          }
        }
      }
    }
    .home-comingsoon {
      margin-bottom: 50px;
      h3 {
        font-size: 23px;
        color: #334958;
        margin-bottom: 40px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
        margin-right: -12px;
        li {
          margin-bottom: 24px;
          margin-left: 12px;
          margin-right: 12px;
          width: calc(33.33% - 24px);
          @media (max-width: 760px) {
            width: calc(50% - 24px);
          }
          @media (max-width: 550px) {
            width: calc(100% - 24px);
          }
          a {
            background: #fff;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
            display: block;
            padding: 20px;
            color: #334958;
            font-size: 14px;
            font-weight: $medium;
            background-image: url("../img/ico_arrow.svg");
            background-repeat: no-repeat;
            background-position: right 20px center;
            @media (max-width: 927px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.product-sub-menu-homepage {
  position: absolute;
  display: block;
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  z-index: 5000;
  width: 100%;
  max-width: 273px;
  padding: 5px 0;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.4s, 0s;
  transition-delay: 0s, 0.4s;
  top: 55px;
  li {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    display: block;
    padding: 0;
    text-align: left;
    background-color: white;
    background: white;
    a {
      color: #334958;
      padding: 15px 20px;
      display: block;
      background: white;
    }
  }
  &:before {
    width: 25px;
    height: 31px;
    transform: rotate(-225deg);
    background: white;
    content: " ";
    position: absolute;
    left: 20px;
    top: -12.5px;
    z-index: 100;
  }
  &:after {
    width: 25px;
    height: 25px;
    transform: rotate(-225deg);
    background: white;
    content: " ";
    position: absolute;
    left: 20px;
    top: -12.5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    z-index: 50;
  }
  &.active {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s, 0s;
  }
}
