.testimonials_area{
    max-width: 700px;
    width: 90%;
    margin: auto;
    padding: 0 0 10rem 0;
    .title_centre{
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 6rem 0 2rem;
        h2{
          margin-bottom: 1.5rem;
        }
      }

    .testimonial{
        text-align: center;
        p{
            font-style: italic;
            font-size: 1.8rem;
            line-height: 1.7;
            margin-bottom: 2rem;
            font-weight: 500;
        }
        h4{
            font-size: 1.4rem;
            font-size: 14px;
            color: #76797B;
            letter-spacing: 0;
            text-align: center;
        }
        h5{
            text-transform: uppercase;
            font-size: 10px;
            color: #76797B;
            letter-spacing: 2px;
            text-align: center;
        }
        .stars{
            display: flex;
            justify-content: center;
            padding: 5rem 0 0 0;
        }
    }

}