

//General Text Area, Type, buttons etc

.general-text{
    h1, h2, h3, h4, p {
        color: #192023;
    }
}

.text-white, 
.text-white .general-text{
    h1, h2, h3, h4, p, a {
        color: #ffffff;
    }
}

.title-lg{
    
    font-size: 56px;
    padding: 15px 0px;
    @media (max-width: 700px) {
        font-size: 46px;
    }
    @media (max-width: 500px) {
        font-size: 36px;
    }
}

.title{
    
    font-size: 39px;
    padding: 10px 0px;
    line-height: 1.3;
    @media (max-width: 500px) {
        font-size: 33px;
    }
}

.title-md{
    font-size: 26px;
    line-height: 1.4;
}

.subtitle{
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #00AF0D;
    padding: 10px 15px;
    width: fit-content;
    @media (max-width: 500px) {
        font-size: 8px;
        padding: 4px 7px;;
    }
}

.subtitle_small_dark{
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #1A1F23;
    padding: 5px 10px;
    width: fit-content;
    @media (max-width: 500px) {
        font-size: 8px;
        padding: 4px 7px;;
    }
}

p a, .txt-link{
    color: #00AF0D;
    text-decoration: underline;
}

.text-center{
    text-align: center;
}




//Image Gallery Row

.title-area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

//Image Gallery Row

.img-gallery-row{
    max-width: 1400px;
    padding: 50px 0;
    margin: auto;
    @media (max-width: 1200px) {
        max-width: 100%;
        width: 100%;
        padding: 20px 0;
    }
    .gallery-outter{
        margin: 0;
        padding: 0;
        width: 100%;
        .item{
            padding: 10px;
            img{
                border-radius: 4px;
                width: 100%;
            }
        }
    }
}




//CTA Area

.cta-area{
    border-radius: 6px;
    padding: 50px 100px;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
    @media (max-width: 600px) {
        padding:8vw ;
    }
    .btn_new{
        width: fit-content;
        @media (max-width: 600px) {
           margin-top: 0;
        }
    }
    h2, h3, p{
        max-width: 800px;
        margin-bottom: 20px;
    }
}

// Banners

.banners{
    max-width: 90%;
    width: 1350px;
    margin: auto;
    margin-top: 50px;
    .row{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px; 
        @media (max-width: 1000px) {
            height: 500px; 
        }
        @media (max-width: 700px) {
            height: 500px; 
        }
        img{
            width: 100%;
            height: 100%; 
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 6px;
            z-index: 0;
        }
        .text-wrap{
            z-index: 2;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 0 8%;
            margin-top: -40px;
            max-width: 1100px;
            @media (max-width: 700px) {
                padding: 0 10%;
                margin-top: 0;
            }
            h3{
                max-width: 700px;
            }
        }
    }
    .banner-search{
        z-index: 100;
        position: relative;
        max-width: 960px;
        margin: auto;
        margin-top: -60px;
    }
}

//Benefits List

.benefits-list{
    .row-benefits{
        margin: 0 -20px ;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 700px) {
            flex-direction: column;
        }
        .item{
            width: calc(33.3% - 40px);
            margin: 20px;
            min-height: 200px;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.034079);
            border-radius: 4px;
            padding:40px 30px;
            @media (max-width: 1000px) {
                width: calc(50% - 40px);
            }
            @media (max-width: 700px) {
                width: calc(100% - 40px);
            }
            .ico-benefit{
                width: 30px;
                margin-bottom: 10px;
            }
            .title-md{
                width: 70%;
            }
        }
    }

}

// Three Column CTA Area
.three-col-cta-area{
    margin-top: 50px ;
    margin-bottom: 100px ;
    @media (max-width: 1000px) {
        margin-top: 25px ;
    margin-bottom: 50px ;
    }
    .row-cta{
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.034079);
        margin-left: 0 ;
        margin-right: 0 ;
        display: flex;
        flex-wrap: nowrap;
        @media (max-width: 1000px) {
            flex-direction: column;
        }
        .col{
            padding:50px 40px;
            flex-basis: auto;
            width: 33.3%;
            @media (max-width: 1000px) {
                width: 100%;
            }
            &:first-child{
                h3{
                    font-size: 30px;
                    line-height: 1.2;
                }
            }
            h3{
                font-size: 24px;
                margin: 20px 0;
            }
            p{
                margin-bottom: 20px;
            }
            .icon{
                background-color: #fff;
                border-radius: 60px;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 60%;
                }
            }
        }
    }

}

//Category Links

.category_links{
    width: 1200px;
    margin: auto;
    max-width: 86%;
    
    .category_link_outter{
        margin: 0 -20px ;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 700px) {
            margin: 0 -15px ;
        }
        .item{
            width: calc(16.666% - 40px);
            margin: 20px;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.034079);
            border-radius: 4px;
            padding:0;
            background-color: #fff;
            overflow:hidden;
            transition: all .2s ease-in-out;
            @media (max-width: 1200px) {
                width: calc(25% - 40px);
                margin:20px;
            }
            @media (max-width: 1000px) {
                width: calc(33.33% - 40px);
            }
            @media (max-width: 700px) {
                width: calc(50% - 30px);
                margin:15px;
            }
            .img-outter{
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.034079);
                width: 100%;
                height: 100px;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            h4{
                color: #76797B;
                font-size: 18px;
                text-align: center;
                width: 100%;
                padding: 16px 10px;
            }
            &:hover{
               
                transform: scale(1.04);
            }
        }
    }

}


//Featured Products
.featured_products{
    width: 1200px;
    padding: 50px 0;
    margin: auto;
    @media (max-width: 1300px) {
        max-width: 100%;
        width: 90%;
        padding: 20px 0;
    }
    .titlearea{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 30px 0 ;
    }

    .slick-slide {
        margin: 0 15px;
      }
      /* the parent */
      .slick-list {
        margin: 0 -15px;
        width: 100%;
      }
    .featured_products_outter{
        margin: 0;
        padding: 0;
        width: 100%;
        margin: auto;
        margin-left: -15px;
        margin-right: -15px;
        .item{
            margin: 10px;
            padding: 10px;
            width: 100%;
        }
    }
}


//Brand Links

.brand_links{
    width: 1200px;
    margin: auto;
    max-width: 86%;
    padding-top: 30px;
    
    .brand_link_outter{
        margin: 0 -20px ;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 700px) {
            margin: 0 -15px ;
        }
        .item{
            width: calc(20% - 40px);
            margin: 20px;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.034079);
            border-radius: 4px;
            padding:40px 20px;
            background-color: #fff;
            overflow:hidden;
            transition: all .2s ease-in-out;
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;
            @media (max-width: 1200px) {
                width: calc(25% - 40px);
                margin:20px;
            }
            @media (max-width: 1000px) {
                width: calc(33.33% - 40px);
            }
            @media (max-width: 700px) {
                width: calc(50% - 30px);
                margin:15px;
            }

                img{
                    width: 80%;
                }
            
            &:hover{
               
                transform: scale(1.04);
            }
        }
    }

}


//Latest News Carousel

.brand_links{
    width: 1200px;
    margin: auto;
    max-width: 86%;
    padding-top: 30px;
    
    .latest_news_outter{
        margin: 0 -20px ;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 700px) {
            margin: 0 -15px ;
        }
        .item{
            width: calc(33.33% - 40px);
            margin: 20px;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.034079);
            border-radius: 4px;
            padding:40px 20px;
            background-color: #fff;
            overflow:hidden;
            transition: all .2s ease-in-out;
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;
            @media (max-width: 700px) {
                width: calc(50% - 30px);
                margin:15px;
            }

                img{
                    width: 80%;
                }
            
            &:hover{
               
                transform: scale(1.04);
            }
        }
    }

}

.latest_news{
    width: 1200px;
    margin: auto;
    max-width: 86%;
    padding: 60px 0 ;
    .latest_news_titlearea{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 30px 0 ;
    }
    .latest_news_outter{
     
   
        
        .slick-slide {
            margin: 0 15px;
          }
          /* the parent */
          .slick-list {
            margin: 0 -15px;
          }
        .item{
            
        }
    }

}

// NEWS ITEM - CARD

.news-item{
        
        margin: 0 ;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        overflow: hidden;
        align-items: flex-start;
        position: relative;
        transition: all .2s ease-in-out;
        background-color: #fff;
        img{
            width: 100%;
            max-height: 34rem;
            height: 22vw;
            border-radius: 4px;
            object-fit: cover;
            overflow: hidden;
            margin-bottom: 2rem;
            @media (max-width: 1000px) {
                height: 32vw;
                max-height: 32vw;
            }
            @media (max-width: 600px) {
                height: 45vw;
                max-height: 45vw;

            }
        }
        .text_outter{
            display: flex;
            flex-direction: column;
            padding: 3rem;
            width: 100%;;
            h4{
                padding-bottom: 1rem;
            }
            h3{
                @extend .body-font;
                padding:0 0 0.5rem 0 ;
                margin-bottom: 1rem;
                width: 100%;
                text-align: left;
                font-size: 2.2rem;
                line-height: 1.4;
                @media (max-width: 1000px) {
                    font-size: 2.5rem;
                }
            }
        }

    
}


//Layout

.container{
    @media (max-width: 1200px) {
        max-width: 90%;
    }
    &.container-md{
        max-width: 800px ;
        @media (max-width: 1200px) {
            max-width: 90%;
        }
    }
    .row{
        display: flex;
        flex-wrap: nowrap;
        .col{
            flex-basis: auto;
        }
        &.two-col{
            margin: 0 -30px ;
            @media (max-width: 900px) {
                flex-direction: column;
            }
            .col{
                width: 50%;
                margin: 50px 0;
                padding: 0 30px;
                flex-grow: 1;
                @media (max-width: 900px) {
                    width: 90%;
                    padding: 0;
                    margin: 25px 0;
                    &.general-text{
                        order: 1;
                    }
                    &.col-img{
                        order: 2;
                    }
                }
                img{
                    border-radius: 6px;
                }
            }
        }
        &.three-col{
            .col{
                width: 33.3%;
            }
        }
        &.four-col{
            .col{
                width: 25%;
            }
        }
        &.flip{
            flex-direction:row-reverse;
        }
    }
    

}

.mx-l{
    margin-top: 30px ;
    margin-bottom: 30px ;
}
.mb-l{
    margin-bottom: 30px ;
}
.mt-l{
    margin-top: 30px ;
}

.middle{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-green-grad{
    background: radial-gradient(89.83% 89.83% at 96.41% 95.23%, #008C0A 0%, #006907 100%);
}
.bg-green-1{
    background-color: #00AF0D;
}
.bg-green-2{
    background-color: #008C0A;
}
.bg-green-3{
    background-color: #006907;
}

.bg-white{
    background: #FFFFFF;
}

.arrow_after{
    :after {
        margin-left: 10px;
        background-color: rgba(255,255,255,0.1);
        border-radius: 50%;
        display: block;
        background-image: url(../img/ico_arrow_left.svg);
        content: ' ';
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
        height: 44px;
        width: 44px;
        position: absolute;
        bottom: 5rem;
        right: 30px;
    }
}