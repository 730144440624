.minimum-header {
  img {
    max-width:195px; 
  }
  h1 {
    font-weight:700;
    font-size: 34px;
    color: #334958;
    text-align: center;
    margin:30px 0;
  }
}

.login_frm {
  background: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.13);
  padding:20px 20px 30px;
  max-width:378px;
  label {
    margin:10px 0;
  }
  input {
    margin: 0 0 20px;
  }
  .forgot {
    text-align: left;
    margin-bottom: 15px;
    a {
      font-size: 12px;
      color: #939393;
      line-height: 1.5;
      text-decoration: underline;
    }
  }
  .btn-wrap {
    span {
      margin:10px 0;
      font-size:12px;
      color:#939393;
    }
    input.btn {
      margin-bottom:0; 
    }
    .btn {
      border-radius: 2px;
    }

  }
}

.logininfo{
  background-color: #fff8ed;
  border: 1px solid #ccc;
  border-radius: 4px; 
  color:#666; 
  font-weight:bold; 
  font-size: 14px; 
  padding: 10px 0;
  margin-top: 10px;
  max-width: 427px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
}