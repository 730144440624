#member-details-wrap, .pagination_members {
    width:100%;
    ul.pagination {
        margin:0 -5px 20px;
        display:inline-block;
        li {
            margin:0 5px;
            width:28px;
            height:28px;
            text-align:center;
            line-height:28px;
            background: #ffffff;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.13);
            display:inline-block;
            font-size:13px;
            line-height:1;
            a, span {
                color:#333;
                display: block;
                line-height:28px;
            }
            &.active {
                background:#00AF0D;
                color:white;
                a, span {
                    color:white;
                }
            }
        }
    }
}