
.checkout-wrap {
    //background-color: white;
    margin-top:35px;
    margin-bottom:50px;
    display: block;
    .nav-tabs {
        vertical-align: top;
        display: flex;
        margin-bottom: 30px;
        li {
            vertical-align: top;
            &.loading {
                padding:0;
            }
        }
    }
    /* checkout buttons */
    .navbar-btn {
        display:flex;
        justify-content: space-between;
        button {
            display: inline-block;
            width:auto;
        }
    }
    .tab-content {
        position: relative;
        .checkoutStep {
           // position: absolute;
          //  top:0;
          //  left:0;
          //  width:100%;
        }   
    }
    .inner-step-wrap {
        padding:40px;
        display: block;
        background: #ffffff;

    }
    .inner-step-wrap-small{
        padding:20px 40px 40px 40px;
        max-width: 640px;
        margin: auto;
        @media(max-width:640px) {
            padding:20px;
        }
    }
    h4, h3 {
        font-size: 19px;
        color: #334958;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 15px;
    }
    input.form-control {
        padding: 15px 20px;
        font-size: 12px;
    }
    table.table {
        width:100%;
        tbody {
            tr {
                box-shadow: none;
            }
            td {
                border:0;
                &.table-image {
                    text-align:center;
                  
                }
            }
        }
    }
    .sw-toolbar {
        margin-top:25px;
        padding-bottom:10px;
    }
    small.form-control-feedback {
        color:#a50505;
    }
    .card.panel {
        position: relative;
        right: auto;
        width:auto;
        box-shadow: none;
    }
    .form-group {
        select {
            margin-top:10px;
            font-size: 12px; 
            color: #3d3d3d;
            border-radius:0;
            height:auto;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
            option {

                padding: 15px 20px;
            }
        }
    }
    .form_inputs{
        display: flex;
        flex-wrap: wrap;
        
        .input-half{
            width: calc(50% - 20px);
            margin: 0 10px 10px 10px;
            @media(max-width:600px) {
                width: calc(100% - 20px);
            }
    
        }
        .input-full{
            width: 100%;
            margin: 0 10px 10px 10px;
    
        }
        
        .select2-normal{
            height: auto !important;
            padding: 15px 20px;
        }
    }

    
}

.checkout-controllers-wrap {
    display: flex;
    align-items:center;
    div.flex-grow {
        flex-grow: 1;
    }
    .prev {
        text-align: left;
        color: #334958;
        font-style: normal;
        font-weight: 500;
    }
}


/* toast messages */
.iziToast {
    & > .iziToast-body > p { float:none; color:white; }
    & > .iziToast-body > strong { float:none; }
    .iziToast-body {
        min-height:auto;
        display:flex;
        align-items:center;
    }
    .iziToast-close {
        position: absolute;
        background-color: #2b5675;
        &:after {
            content:"\ea04";
            font-family: feather!important;
            color:white;
            opacity: 1;
            font-size:21px;
        }

    }
    .iziToast-buttons {
        display: none;
    }
}

.btn-cancel {
    display: block;
    text-align:center;
    color:#334958;
    margin:5px 0;
    padding:0;
}


.sw-btn-prev {
    background: none;
    width: auto;
    border: 0;
    padding: 0;
    outline: none;
    color: #334958;
    box-shadow: none;
    &:before {
        content: "\f053";
        font-family: FontAwesome;
        display: inline-block;
        margin-right: 10px;
    }
    &:hover {
        background: none;
        color: #334958;
        text-decoration: underline;
    }
    &.disabled {
        opacity: 0;
        pointer-events: none;
    }

}

h4.product-title,
h5.product-title {
    font-size: 16px;
    color: #334958;
    line-height: 1.35;
    font-style: normal;
    font-weight: 500;
    a {
        color:#334958;
    }
}



.confirmation-header {
    display:flex;
    margin-top:20px;
    margin-bottom:10px;
    @media(max-width:600px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .success-address {
        font-size: 13px;
        color: #334958;
        line-height: 1.35;
        font-style: normal;
        font-weight: 500;
    }
    > div {
        width:50%;
        &:nth-child(2) {
            text-align:right;
        }
        @media(max-width:600px) {
            width:100%;
            &:nth-child(2) {
                text-align:left;
                margin-top: 25px;
            }
        }
        h4 {
            font-size:16px;
            color: #334958;
            margin-bottom:5px;
        }
    }
}