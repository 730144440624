table {
    width:100%;
    border-collapse:separate; 
    border-spacing: 0 1em;

    tbody {
        tr {
            background: #FFFFFF;
           
            margin:10px 0;
            
            overflow: hidden;
            td {
                padding:15px 20px;
                font-size: 12px;
                color: #525252;
                border-bottom:1px #ececec solid !important;
                border-top: none !important;
                &:nth-child(1) {
                    padding:20px 20px 20px 30px;
                }
                &:last-child {
                    padding:20px 30px 20px 20px;
                }
            }
        }
    }
}