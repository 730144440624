.quote-frm-wrap {
  //max-width:427px;
  max-width: 842px;
  margin: 50px auto;
  text-align: center;
  &.product-listings {
    max-width: 960px;
    .btn-wrap {
      max-width: 960px;
    }
  }
  h2 {
    font-size: 50px;
    line-height: 1.2;
    font-style: normal;
    font-weight: $medium;
    color: #1a1f23;
    font-family: $title-font;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  h3.subTitle {
    margin-top: 35px;
    font-weight: $medium;
    font-size: 19px;
    color: $titleblue;
    text-align: center;
  }
  p {
    font-size: 19px;
    color: #242525;
    text-align: center;
    line-height: 1.5;
    max-width: 504px;
    margin: 0 auto;
    &.pricing-range-overview {
      font-size: 17px;
      color: #626262;
      text-align: center;
      line-height: 1.35;
      max-width: 674px;
      margin-bottom: 15px;
      font-weight: $regular;
      strong {
        font-weight: $semi-bold;
        color: $green;
      }
    }
    &.select-product-overview-helper {
      margin: 35px auto 0;
      max-width: none;
      color: #00AF0D;
      font-weight: 600;
      background-color: white;
      padding: 10px 30px;
      display: inline-block;
    }
    &.pricing-range-details {
      font-size: 12px;
      color: #939393;
      text-align: center;
      line-height: 19px;
      max-width: 674px;
    }
    &.pricing-info-panel-btm {
      font-size: 12px;
      color: #787878;
      font-weight: 300;
      margin: 0 0 25px;
      max-width: none;
      text-align: left;
      a {
        color: #787878;
        text-decoration: underline;
      }
    }
    &.small{
      font-size: 12px;
    }
  }
  .statusinfo{
    display: flex;
    max-width: 700px;
    margin: 0 auto;
    padding: 30px 0 40px 0;
    .info{
      width: 50%;
      margin: 0 20px;
      padding: 30px 20px 20px 20px;
      border-radius: 4px;
      position: relative;
      .status-icon{
        width: 44px;
        position: absolute;
        top: -15px;
        left: calc(50% - 22px);
      }

    }
  }

  .select-product-overview-helper-icon {
    position: relative;
    top: 18px;
    left: 15px;
    @media (max-width: 960px) {
      display: none;
    }
  }
  a.view-full-product-spec {
    color: #00AF0D;
    font-size: 13px;
    line-height: 1.35;
    text-decoration: underline;
  }
  form {
    margin: 0;
    padding:0 20px 40px 20px;
    @media (max-width: 600px) {
      padding: 30px 20px ;
    }
    @media (max-width: 450px) {
      padding: 25px 20px;
    }
    .optiontitle {
      display: block;
      text-align: left;
      font-size: 18px;
      color: $titleblue;
      font-style: normal;
      font-weight: $medium;
      margin-bottom: 20px;
    }
    ul.select_options_wrap {
      max-width: $quoteformmaxwidth;
      margin: 0 auto;
      &.extendwidth {
        max-width: $quoteforminnerwidth;
      }
      li {
        .check_wrap {
          display: flex;
          align-items: stretch;
          align-content: stretch;
          flex-wrap: wrap;
          //justify-content: space-between;
          margin: 10px -5px 36px;
          @media (max-width: 475px) {
            flex-wrap: wrap;
            margin: 0;
          }
          .check_wrap_item {
            display: block;
            background: #ffffff;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
            position: relative;
            top: 0;
            margin: 5px;
            //width: 100%;
            @media (max-width: 475px) {
              width: calc(100% - 10px);
              margin-bottom: 10px;
            }
            &:nth-child(1) {
              //margin-left: 0;
            }
            @media (max-width: 475px) {
              width: calc(100%);
              margin-left: 0;
              margin-right: 0;
              &:nth-child(2n + 1) {
                margin-left: 0;
              }
            }
            &:active {
              top: 1px;
            }
            .icheckbox_flat-blue {
              display: none;
            }
            label {
              font-size: 14px;
              color: #5a5a5a;
              text-align: center;
              font-style: normal;
              font-weight: $medium;
              cursor: pointer;
              padding: 18px;
              line-height: 1.35;
            }
            &.selected {
              background-color: $brandingblue;
              label {
                color: #fff;
              }
            }
            &.disabled {
              opacity: 0.3;
              pointer-events: none;
            }
          }
          &.check_wrap_dropdown {
            display: block;
            position: relative;
            .check_wrap_item {
              margin: 0;
            }
            .select_item {
              position: relative;
              cursor: pointer;
              font-size: 14px;
              color: #5a5a5a;
              text-align: center;
              font-style: normal;
              font-weight: 500;
              cursor: pointer;
              padding: 18px;
              line-height: 1.35;
              &:after {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f107";
                color: #334958;
                position: absolute;
                top: 14px;
                right: 15px;
                font-size: 18px;
              }
              &.selected {
                color: white;
                &:after {
                  color: white;
                }
              }
            }
            .check_wrap_dropdown_items {
              position: absolute;
              z-index: 100;
              height: 300px;
              width: 100%;
              overflow-y: scroll;
              display: none;
              background-color: white;
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
              &.show {
                display: block;
              }
            }
          }
        }
      }
    }

    p,
    .btn.full-width,
    .select-wrap {
      max-width: 427px;
      margin: 0 auto;
      &[disabled=""] {
        pointer-events: none;
        background: #ccc;
      }
    }

    p{
      margin-bottom: 10px;
    }

    .input_note {
      font-size: 11px;
      color: #939393;
      display: block;
      text-align: left;
    }
    p.explain_txt {
      display: block;
      text-align: left;
      margin: 15px auto;
      font-size: 13px;
      color: #939393;
      line-height: 20px;
      max-width: 427px;
    }
    .notification_txt {
      display: block;
      text-align: left;
      margin: 15px auto;
      font-size: 13px;
      color: #939393;
      line-height: 20px;
      max-width: 427px;
      padding-bottom: 10px;
    }
    .select-wrap {
      display: flex;
      justify-content: space-between;
      margin: 0 auto 25px;
      span {
        font-size: 14px;
        color: #5a5a5a;
        text-align: center;
        border: 2px #a8a8a8 solid;
        border-radius: 4px;
        width: calc(50% - 7.5px);
        padding: 10.5px;
        cursor: pointer;
        position: relative;
        &:active {
          top: 1px;
        }
        &.selected {
          background: #1a1f23;
          border: 2px $titleblue solid;
          color: #fff;
        }
      }
    }

    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 2px #ebebeb solid;
      padding-top: 25px;
      max-width: $quoteformmaxwidth;
      margin: 25px auto;
      min-height: 93px;
      a,
      span {
        background: none;
        width: auto;
        border: 0;
        padding: 0;
        outline: none;
        color: $titleblue;
        i {
          color: $titleblue;
          font-style: normal;
          font-weight: 600;
        }
      }
      @media (max-width: 600px) {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .btn {
        display: inline-block;
        width: 100%;
        max-width: 110px;
        border-radius: 2px;
        @media (max-width: 600px) {
          max-width: none;
        }
      }
      .select-product-btn,
      .back-btn {
        @media (max-width: 600px) {
          order: 3;
          width: 100%;
        }
      }
      .btns {
        @media (max-width: 600px) {
          text-align: center;
          width: 100%;
          margin-bottom: 10px;
        }
        .btn {
          width: auto;
          max-width: none;
          margin-left: 12px;
          @media (max-width: 600px) {
            margin-top: 12px;
            margin-left: 0;
            width: 100%;
          }
        }
        .third-btn {
          padding: 14px 25px;
          border: 2px #a8a8a8 solid;
          border-radius: 4px;
          cursor: pointer;
          @media (max-width: 700px) {
            display: none;
          }
        }
      }
    }


  }
}

.v-spacing {
  height: 30px;
  width: 100%;
  display: block;
}

.product-listing-panel {
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 0 30px;
  position: relative;
  .col-wrap {
    display: flex;
    @media (max-width: 960px) {
      flex-direction: column;
    }
    .product-info-panel {
      width: calc(100% - 250px);
      flex-grow: 0;
      flex-basis: auto;
      padding: 0;
      &.hidepriceinfo {
        width: 100%;
      }
      @media (max-width: 960px) {
        width: 100%;
      }
      .top {
        display: flex;
        border-bottom: 1px #e8e9ec solid;
        padding: 30px;
        @media (max-width: 600px) {
          flex-direction: column;
          padding: 20px;
        }
        &.results{
          @media (max-width: 900px) {
            flex-direction: column;
            padding: 6%;
            .txt-wrap{
              width: 100%;
              margin-top: 30px;
              padding: 0;
            }
          }

        }
        .img-cycle-wrap {
          width: 220px;

          position: relative;
          overflow: hidden;
          display: flex;
          border: 1px solid #e8e8e8;
          @media (max-width: 900px) {
            width: 49.5%;
            order: 2;
          }
          @media (max-width: 600px) {
            width: 100%;
          }
          .owl-stage-outer {
            height: 100%;
          }
          .owl-stage {
            display: flex;
            height: 100%;
          }
          a.item {
            position: relative;
            display: block;
            height: 100%;
            .counter {
              background: #424242;
              color: #fff;
              text-align: center;
              padding: 3px 10px;
              font-weight: 600;
              font-size: 9px;
              color: #ffffff;
              position: absolute;
              top: 20px;
              left: 0;
            }
            img {
            }
            span.v-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              .v-wrapper-inner {
                display: block;
                img {
                  min-height: 233px;
                  object-fit: cover;
                }
              }
            }
          }
          .owl-nav {
            position: absolute;
            top: calc(50% - 13px);
            width: 100%;
            .owl-prev,
            .owl-next {
              background: #1a1f23;
              color: #fff;
              width: 26px;
              height: 26px;
              overflow: hidden;
              display: inline-block;
              cursor: pointer;
              font-size: 0;
              text-align: center;
              line-height: 26px;
              position: relative;
              &:after {
                font-family: "Font Awesome 5 Free";
                font-weight: 700;
                font-size: 18px;
              }
              &:active {
                top: 1px;
              }
            }
            .owl-prev {
              position: absolute;
              left: 0;
              &:after {
                content: "\f104";
              }
            }
            .owl-next {
              right: 0;
              position: absolute;
              &:after {
                content: "\f105";
              }
            }
          }
        }
        .img-wrap {
          width: 220px;
          @media (max-width: 900px) {
            width: 50%;
            order: 2;
          }
          @media (max-width: 600px) {
            width: 100%;
          }
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          img {
            opacity: 0;
          }
        }
        .product-img-wrap{
          width: 220px;
          @media (max-width: 900px) {
            width: 100%;
            display: block;
          }

          img {
            width: 100%;
          }
        }
        .large-img-wrap {
          width: 350px;
          @media (max-width: 900px) {
            width: 50%;
            order: 2;
          }
          @media (max-width: 600px) {
            width: 100%;
          }
          .img-wrap {
            width: 100%;
          }
          a.btn {
            display: block;
            width: 100%;
            margin: 10px 0;
            padding: 10px;
            font-size: 14px;
          }
        }
        .txt-wrap {
          width: calc(100% - 220px);
          padding: 0 0 0 30px;
          @media (max-width: 900px) {
            width: 50%;
            padding: 0 30px 0 0;
          }
          @media (max-width: 600px) {
            width: 100%;
            padding: 0 20px 0 0;
          }

          &.minor-txt-wrap {
            width: calc(100% - 350px);
            padding: 30px;
            padding-top: 0;
            padding-bottom: 0;
            @media (max-width: 900px) {
              width: 50%;
            }
            @media (max-width: 600px) {
              width: 100%;
              padding: 0;
            }
          }
          text-align: left;
          .product-brand-wrap {
            margin-bottom: 10px;
            img {
              max-height: 35px;
              height: auto;
              width: auto;
            }
          }
          h3 {
            font-size: 26px;
            font-style: normal;
            color: #1a1f23;
            font-family: "bitter",serif;
            font-weight: 700;
                  }
          h4.extra-title {
            font-family: $title-font-family;
            font-weight: 600;
            font-size: 14px;
            color: $titleblue;
            margin: 10px 0;
          }
          ul.spec {
            margin: 10px 0;
            display: flex;
            flex-wrap: wrap;
            li {
              list-style: none;
              display: inline-block;
              margin-bottom: 15px;
              width: 33%;
              &:nth-child(3n + 1) {
                padding-left: 0;
              }
              span {
                display: block;
                font-style: normal;
                font-weight: $medium;
                font-size: 10px;
                color: #b3b3b3;
              }
              strong {
                display: block;
                font-size: 15px;
                color: #5a5a5a;
                font-style: normal;
                font-weight: $medium;
              }
            }
          }
        }
      }
      .btm {
        padding: 20px;
        text-align: left;
        h4 {
          font-style: normal;
          font-weight: $medium;
          font-size: 16px;
          margin-bottom: 10px;
          color: $titleblue;
        }
      }
      &.product-panel-padding {
        padding-right: 0;
        .top {
          border-bottom: 0;
        }
        @media (max-width: 900px) {
          padding-left: 0;
          padding-right: 20px;
        }
        @media (max-width: 600px) {
          padding: 0;
        }
      }
    }
    .pricing-info-panel {
      flex-grow: 0;
      flex-basis: auto;
      padding: 0;
      background-color: #274856;
      color: #fff;
      padding: 20px;
      width: 250px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 960px) {
        width: 100%;
      }
      .previous-ordered-txt {
        background: #f7f5d6;
        border: 1px solid #dad9bd;
        padding: 5px 10px;
        margin-bottom: 15px;
        color: #334958;
        font-size: 11px;
        strong {
          font-weight: 600;
        }
      }

      .top {
        h4 {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          color: #ffffff;
          line-height: 1;
          margin: 5px 0;
          &.avg {
            font-weight: 700;
            font-size: 13px;
            color: #ffffff;
            margin-bottom: 15px;
          }
        }
        h3 {
          font-size: 26px;
          color: #ffffff;
          font-style: normal;
          font-weight: 700;
          line-height: 1;
          margin: 5px 0;
        }
        h5 {
          opacity: 0.65;
          font-size: 12px;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          line-height: 1;
          margin: 5px 0;
        }
        .secondary-btn {
          font-size: 14px;
          display: block;
          border-radius: 0;
          margin: 14px 0;
        }
        p.helper {
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          margin: 0;
          text-align: left;
        }
        .select-product-item {
          border: 0;
          width: 100%;
          display: block;
          margin: 0 0 10px 0;
          position: relative;
          border-radius: 0;
          &:active {
            top: 1px;
          }
          cursor: pointer;
          span {
            cursor: pointer;
          }
          .icheckbox_flat-blue {
            display: none;
          }
          .deselect {
            display: none;
          }
        }
      }
      .btm {
        div {
          margin: 5px 0;
          a {
            opacity: 0.65;
            font-size: 12px;
            color: #ffffff;
            text-decoration: underline;
          }
        }
      }
    }
  }
  &.selected {
    &:after {
      background: $green;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: -15px;
      right: -15px;
      content: "\f00c";
      font-family: FontAwesome;
      color: #fff;
      font-size: 18px;
      line-height: 38px;
    }
    .pricing-info-panel {
      .top {
        .select-product-item {
          background-color: #276f8e;
          .select {
            display: none;
          }
          .deselect {
            display: block;
          }
        }
      }
    }
  }
}

.select-product-btn,
.back-btn {
  cursor: pointer;
}


.recommend-dealers-wrap {
  margin-top:30px;
  h4 {
    font-size: 18px;
    color: #334958;
    line-height: 1.35;
    font-style: normal;
    font-weight: 500;
    margin: 10px 0 20px 0;
  }
  ul {
    li {
      display:flex;
      align-items: center;
      border-top: 1px solid #e2e2e2;
      padding: 30px 0 20px 0;
      @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
      }
      &:nth-child(1) {
        margin-top:0;
      }
      div.img-with-profile {
        flex-grow:1;
        display:flex;
        @media (max-width: 800px) {
          width: 100%;

        }
        .dealer-logo{
          width:50px;
          margin-right:20px;
          position: relative;
          img {

          }
          .featured{
            width:22px;
            height: 17.5px;
            margin-right:0;
            position: absolute;
            top: -5px;
            left: -10px;

          }
        }
        .dealer-text{
          padding-right: 20px;
          h4 {

          }
          h3 {

          }
          h5 {
            font-size: 16px;
            color: $titleblue;
            font-style: normal;
            font-weight: $bold;
            margin-bottom: 0;
          }
          h6{
            font-size: 14px;
            color: #939393;
            font-style: normal;
            font-weight: $bold;
            margin-bottom: 0;
          }
          p{
            text-align: left;
          }
        }


      }
      a.btn {
        padding:12px 17px;
        font-size:14px;
      }
      div.btn-outter{
        @media (max-width: 800px) {
          width: 100%;

        }
      }
    }
  }
  &.quote-details{
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.13);
    margin: 10px 0 30px;
    position: relative;
    padding: 40px;
    margin-bottom: 80px;
    h3{
      font-size: 25px;
      color: #334958;
      line-height: 1.35;
      margin-bottom: 30px;
    }
  }
}

.results-btm{
  background-color: #fff;
  border-radius: 4px;
  padding: 0;
  margin-top: 11rem;
  overflow: hidden;
  .loginmessage{
    padding: 50px 50px 10px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn-mg-btm{
      margin: 30px 0;
    }
    h3{
      font-size: 30px;
      line-height: 1.2;
      font-style: normal;
      
      color: #fff;
      font-family: $title-font;
      font-weight: 700; 
    }
    p{
      max-width: 80%;
      padding: 0;
      color: #fff;
    }
    
  }
  
  .small_message{
    width: 100%;
    padding: 50px;
    height: fit-content;
    @media (max-width: 600px) {
      padding: 30px 20px ;
    }
    .inner_message{
      display: flex;
      flex-direction: row;
      @media (max-width: 800px) {
        display: block;
        flex-direction: none;
      }
      .col{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
 
        @media (max-width: 800px) {
          width: 100%;
        }
        &:first-child{
          h3{
            padding-right: 20%;
            @media (max-width: 800px) {
              margin-bottom: 20px;
            }
          }
          
        }
        h3{
          font-size: 30px;
          
          font-style: normal;
      
          color: #1a1f23;
          font-family: $title-font;
          font-weight: 700; 
        }
        ul{
          height: fit-content;
          li{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            &:last-child{
              margin-bottom: 0;
            }
            &:before {
              content: "";
              font-family: FontAwesome;
              display: inline-block;
              width: 20px;
              height: 20px;
              display: block;
              margin-right: 10px;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url('/assets/themes/reflex/img/tick-featured.png') ;
            }
          }
        }
      }
    } 
  }
}


.fix-btn-get-quotes-now {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 10000;
  background-color: rgba(#e0e0e0, 0.95);
  .btn {
    margin: 0;
  }
  .btn-wrap {
    margin: 0 auto;
    padding-top: 0;
    border: 0;
    width: 90%;
  }
}