.member-top {
  background: #fff;
  color: #fff;
  border-bottom: 1px #e3e3e3 solid;
  .inner-wrap {
    padding: 71px 0 41px;
    display: flex;
    @media (max-width: 900px) {
      padding: 80px 0 45px 0;
    }
    @media (max-width: 600px) {
      
    }
    @media (max-width: 450px) {
      
    }
    .col {
      padding: 0;
      margin: 0;
      flex-grow: 0;
      flex-basis: auto;
      width: auto;
      &:nth-child(1) {
        flex-grow: 1;
      }
      h3 {
        font-weight: $medium;
        font-size: 25px;
        color: $titleblue;
        margin: 0;
        line-height: 1;
        padding: 0;
        @media (max-width: 900px) {
          font-size: 25px;
        }
        @media (max-width: 600px) {
          font-size: 22px;
        }
        @media (max-width: 450px) {
          font-size: 19px;
        }
      }
      a.btn {
        padding: 12px 25px;
        @media (max-width: 600px) {
          display: none;
        }
      }
    }
  }
  .breadcrumbs-wrap {
    padding: 30px 0;
  }
}

.member-mid-inner {
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  .dealer-menu {
    width: 336px;
    margin-right: 95px;
    margin-bottom: 65px;
    @media (max-width: 1150px) {
      margin-right: 65px;
      width: 300px;
    }
    @media (max-width: 900px) {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%;
    }
    ul {
      background: #ffffff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
      @media (max-width: 900px) {
        display: none;
      }
      li {
        font-weight: 600;
        font-size: 14px;
        color: #334958;
        border-top: 1px solid #e7e8eb;
        &.menu-title {
          font-size: 25px;
          color: #334958;
          line-height: 1.5;
          padding: 20px 30px;
          @media (max-width: 1150px) {
            font-size: 20px;
          }
        }
        a {
          color: #334958;
          padding: 20px 30px 20px 21px;
          text-decoration: none;
          display: block;
          border-left: 9px #fff solid;
        }
        &.active {
          a {
            border-left: 9px #4e9ed7 solid;
          }
        }
      }
    }
    .select_style {
      display: none;
      @media (max-width: 900px) {
        display: block;
      }
    }
  }
  .member-mid-content {
    flex: 1;
  }
  .dealer-menu + .member-mid-content {
    .quote-frm-wrap {
      text-align: left;
      max-width: none;
      width: 100%;
      
      h2,
      p {
        text-align: left;
        max-width: none;
        margin-left: 0;
      }
      p {
        input {
          max-width: 310px;
        }
      }
      .btn {
        margin-left: 0;
        max-width: 310px;
        &.full-width {
          max-width: none;
        }
      }

      .logo-uploader {
        margin: 0;
        padding: 0;
        background: none;
      }
      .logo-uploader-wrap {
        margin: 5px 0 15px;
        background: #ffffff;
        border: 1px solid #cfcfcf;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: center;
        align-items: center;
        input {
          max-width: none;
          width: auto;
          box-shadow: none;
          padding: 0;
          margin: 10px 0;
        }
      }

      .form-col-wrap {
        display: flex;
        margin: 0 -20px;
        @media (max-width: 600px) {
          flex-direction: column;
          flex-wrap: wrap;
        }
        .form-col {
          width: 50%;
          padding: 0 20px;
          @media (max-width: 600px) {
            width: 100%;
            margin-bottom: 25px;
          }
          p {
            margin-left: 0;
            margin-right: 0;
            input {
              width: 100%;
              max-width: none;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }

    ul.listBrands {
      margin-bottom: 35px;
      li {
        width: calc(33% - 21px);
        max-width: none;
        @media (max-width: 600px) {
          width: calc(50% - 21px);
        }
        @media (max-width: 450px) {
          width: calc(100% - 21px);
        }
      }
    }
  }
}

ul.products_grid {
  margin: 5px -10px 40px;
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    flex-direction: column;
    width: calc(25% - 20px);
    @media (max-width: 1100px) {
      width: calc(33.333% - 20px);
    }
    @media (max-width: 750px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 450px) {
      width: calc(100% - 20px);
    }
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    margin: 10px;
    .top {
      height: 199px;
      text-align: center;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      img {
        height: 199px;
        max-width: none;
        opacity: 0;
      }
      .new-offers {
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        background: #4e9ed7;
        border-radius: 15.5px;
        display: inline-block;
        padding: 6.5px 20px;
        position: absolute;
        left: 7px;
        top: 15px;
      }
      .delete-offer-btn {
        position: absolute;
        right: 0;
        top: 15px;
        background: #1a1f23;
        color: #fff;
        border-radius: 15.5px 0 0 15.5px;
        width: 38px;
        text-align: right;
        padding: 7.5px 3.5px;
        padding-right: 10px;
        line-height: 1;
        cursor: pointer;
        i {
          font-size: 14px;
          line-height: 1;
        }
        &:active {
          top: 16px;
        }
      }
    }
    .mid {
      padding: 15px 20px;
      flex-grow: 1;
      border-top: 1px #e7e8eb solid;
      .product-brand-wrap {
        margin-bottom: 9px;
        img {
          height: 17px;
        }
      }
      h3, h3 a {
        font-size: 15px;
        color: #334958;
        font-family: $standard-font-family;
        font-weight: $semi-bold;
        margin: 0 0 10px;
      }
      .customerdetails {
        h4 {
          font-weight: $medium;
          font-size: 10px;
          color: #939393;
          font-family: $standard-font-family;
          font-weight: $semi-bold;
          line-height: 1;
        }
        h5 {
          font-weight: $medium;
          font-size: 10px;
          color: #939393;
        }
      }

      .quoteprice {
        margin-top: 15px;
        h4 {
          font-weight: $medium;
          font-size: 10px;
          color: #939393;
          font-family: $standard-font-family;
          font-weight: 600;
          line-height: 1;
        }
        h5 {
          font-size: 36px;
          color: #334958;
          font-family: $title-font-family;
          font-weight: $semi-bold;
          line-height: 1;
          @media (max-width: 600px) {
            font-size: 32px;
          }
          @media (max-width: 450px) {
            font-size: 26px;
          }
          @media (max-width: 350px) {
            font-size: 20px;
          }
        }
      }
    }
    .btm {
      padding: 20px;
      border-top: 1px #e7e8eb solid;
      form {
        margin: 0;
        .iCheck-helper,
        .icheckbox_flat-blue {
          display: none;
        }
        input {
          font-size: 14px;
        }
      }
      p {
        font-size: 13px;
        color: #334958;
        strong {
          font-weight: 600;
        }
        &.action-wrap {
        }
        .submitted-price {
          display: block;
          padding-bottom: 10px;
          .submitted-price-lbl {
            display: block;
            font-weight: $light;
            font-size: 10px;
            color: #939393;
          }
          .submitted-price-val {
            display: block;
            font-family: $title-font-family;
            font-size: 36px;
            font-weight: $light;
            color: #334958;
            line-height: 1;
          }
        }
      }
      a.btn {
        display: block;
        font-weight: $light;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        margin-top: 10px;
        padding: 13.5px 15px;
      }

      .no-longer-available {
        display: block;
        margin-left: 0;
      }
      .enquiry_details {
        margin-bottom: 40px;
        h4, p{
          font-size: 13px;
          color: #334958;
          font-weight: 600;
          span {
            color: #56884d;
          }
        }
        h4{
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.no-longer-available {
  display: inline-block;
  font-weight: $light;
  font-size: 14px;
  color: #333;
  text-align: center;
  margin-top: 10px;
  padding: 13.5px 15px;
  background: #eee;
  line-height: 1;
  border-radius: 4px;
  margin-left: 10px;
}

.breadcrumbs {
  font-weight: $medium;
  font-size: 15px;
  color: $titleblue;
  a {
    text-decoration: underline;
    color: $titleblue;
  }
}

.info-details-wrap {
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  margin-bottom: 55px;
  margin-top: 10px;
  h2{
    padding: 20px 25px 5px 25px;
    font-size: 22px;
  }
  .top {
    padding: 10px 25px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media (max-width: 960px) {

      padding: 15px 25px;
    }
    .col {
      display: inline-block;
      font-weight: 500;
      color: #334958;
      flex-grow: 0;
      flex-basis: auto;
      width: auto;
      vertical-align: top;
      background: #eee;
      margin: 10px;
      font-size: 16px;
      padding: 10px 22px;
      border-radius: 4px;
      strong {
        display: block;
        font-weight: $regular;
        font-size: 12px;
        color: #939393;
      }



      @media (max-width: 450px) {
        width: 100%;
        margin: 7.5px 0;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 15px;
        border-left: 0;
      }
    }
  }
  .btm {
    padding: 30px 20px;
    display: flex;
    .col {
      width: 50%;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      flex-wrap: wrap;
      .col {
        width: 100%;
        margin: 0;
        padding: 0;
        flex-basis: auto;
      }
    }
    p {
      margin: 5px 0;
    }
    ul.price_break_down_grid {
      margin: 5px 0;
      @media (max-width: 700px) {
        margin-bottom: 25px;
      }
      li {
        display: flex;
        background: #f5f5f5;
        border: 1px solid #e8e9ec;
        font-weight: 600;
        font-size: 11px;
        color: #5a5a5a;
        margin: 8px 0;
        align-items: center;
        span {
          border-right: 1px #e8e9ec solid;
          padding-right: 10px;
          margin-right: 30px;
          width: 140px;
          padding: 8px 10px;
        }
        .lbl {
        }
        .value {
        }
      }
    }
    &.btm-actions {
      border-top: 1px #dbdbdb solid;
      display: block;
      a.btn {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        max-width: 170px;
        border-radius: 4px;
        padding: 11px 25px;
        margin-right: 10px;
      }
    }
  }
}

.delete-wrapper {
  padding: 0 30px;
  font-size: 14px;
  color: #828282;
  position: absolute;
  line-height: 1.35;
  width: 100%;
  height: 100%;
  background: #fff;
  .btn-wrap {
    margin-top: 25px;
    text-align: center;
    a {
      display: block;
      width: 100%;
      color: #828282;
      font-size: 14px;
      text-decoration: none;
      &.btn {
        color: #fff;
        margin-bottom: 5px;
      }
    }
  }
}

ul.members-offers-grid {
  display: flex;
  margin: 0 -25px;
  flex-wrap: wrap;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  li {
    width: calc(50% - 50px);
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    margin: 25px;
    @media (max-width: 960px) {
      width: calc(100% - 50px);
    }
    position: relative;
    .attach-badges {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 25px;
      vertical-align: top;
      .badge {
        background: #1a1f23;
        margin: 0 10px;
        color: #fff;
        text-align: center;
        padding: 6px 10px;
        border-radius: 0;
        width: 57.8px;
        height: 62px;
        vertical-align: top;
        .badge-ico {
          height: 23px;
          display: block;
          width: 100%;
          text-align: center;
          margin-bottom: 6px;
          i {
            font-size: 24px;
          }
        }
        &.best-price {
          background-color: $green;
          padding-top: 8px;
          .badge-ico {
            height: 26px;
          }
        }
        span {
          font-size: 7px;
          font-weight: 700;
          color: #ffffff;
          display: block;
          text-transform: uppercase;
          line-height: 1.35;
        }
      }
    }
    .top {
      padding: 30px;
      display: flex;
      @media (max-width: 600px) {
        flex-direction: column;
        padding-top: 95px;
      }
      .img-wrap {
        height: 80px;
        width: 80px;
        text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
        @media (max-width: 600px) {
          display: inline-block;
          margin: 0 auto 10px;
        }
      }
      .txt-wrap {
        width: calc(100% - 80px);
        padding: 10px 0 10px 15px;
        @media (max-width: 600px) {
          width: calc(100%);
          text-align: center;
          padding: 0;
        }
        h4 {
          font-size: 17px;
          color: #334958;
          font-family: $standard-font-family;
          font-weight: 700;
          @media (max-width: 600px) {
            margin-bottom: 15px;
          }
        }
        ul {
          margin-top: 7px;
          @media (max-width: 600px) {
            margin-top: 15px;
          }
          li {
            box-shadow: none;
            width: auto;
            font-weight: 600;
            font-size: 12px;
            color: #b3b3b3;
            margin: 0;
            display: inline-block;
            padding-left: 20px;
            border-left: 1px #dbdbdb solid;
            margin-left: 20px;
            &:nth-child(1) {
              padding-left: 0;
              margin-left: 0;
              border-left: 0;
            }
            strong {
              font-weight: 600;
              display: block;
              font-size: 15px;
              color: #5a5a5a;
            }
            @media (max-width: 400px) {
              display: block;
              width: 100%;
              border-left: 0;
              margin-left: 0;
              padding-left: 0;
              text-align: left;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .mid {
      display: flex;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      .col {
        width: 50%;
        padding: 30px;
        @media (max-width: 600px) {
          width: calc(100%);
          flex-basis: auto;
          flex-grow: 0;
        }
        background-color: #438ec3;
        &:nth-child(even) {
          background-color: #4e9ed7;
        }
        h5 {
          font-family: $standard-font-family;
          font-weight: 700;
          font-size: 14px;
          color: #ffffff;
        }
        h3 {
          font-family: $title-font-family;
          font-size: 36px;
          color: #ffffff;
          line-height: 1;
          margin: 0 0 10px;
        }
        h6 {
          opacity: 0.65;
          font-weight: 600;
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
    .btm {
      padding: 15px 30px;
      p.order-id {
        font-weight: 700;
        font-size: 28px;
        color: #00AF0D;
        line-height: 1.35;
        label {
          margin-bottom: 3px;
        }
      }
      a.btn {
        display: block;
        width: 100%;
        margin: 15px 0;
        font-size: 14px;
      }
    }
  }
}

ul.members-three-cols {
  margin: 35px 0;
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  li {
    width: 33.333%;
    padding: 35px;
    border-left: 1px #e8e9ec solid;
    @media (max-width: 960px) {
      width: 100%;
      border-left: 0;
    }
    &:nth-child(1) {
      border-left: 0;
    }
    h3 {
      font-weight: $medium;
      font-size: 19px;
      color: #334958;
      margin: 0 0 15px;
    }
    .img-wrap {
      display: inline-block;
      width: 100px;
      vertical-align: top;
      @media (max-width: 450px) {
        width: 100%;
        text-align: center;
      }
    }
    .txt-wrap {
      width: calc(100% - 105px);
      display: inline-block;
      vertical-align: top;
      font-size: 13px;
      color: #a7b8c4;
      @media (max-width: 450px) {
        width: 100%;
      }
    }
  }
}

ul.members-half-cols {
  margin: 0;
  display: flex;
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  @media (max-width: 600px) {
    flex-direction: column;
  }
  li {
    width: 50%;
    text-align: center;
    border-left: 1px #e8e9ec solid;
    height: 212px;
    @media (max-width: 600px) {
      height: auto;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    &:nth-child(1) {
      border-left: 0;
    }
    @media (max-width: 600px) {
      width: 100%;
      border-left: 0;
      &:nth-child(1) {
        border-bottom: 1px #e8e9ec solid;
      }
    }
    h3 {
      font-family: $title-font-family;
      font-weight: 700;
      font-size: 25px;
      color: #334958;
      text-align: center;
      line-height: 1.3;
      margin: 0 0 25px;
      @media (max-width: 900px) {
        font-size: 22px;
      }
      @media (max-width: 600px) {
        font-size: 19px;
      }
      @media (max-width: 450px) {
        font-size: 17px;
      }
    }
    p {
      margin: 10px 0;
      a {
      }
    }
  }
}



.members-dealer-options {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  li, .col {
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    font-weight: 600;
    font-size: 15px;
    color: #334958;
    text-align: center;
    margin: 20px;
    width: calc(25% - 40px);
    @media (max-width: 900px) {
      margin: 10px;
      width: calc(50% - 20px);
    }
    @media (max-width: 450px) {
      width: calc(100% - 20px);
    }
    a, .link {
      display: block;
      padding: 45px 25px;
      color: #334958;
      span.ico-wrap {
        display: block;
        text-align: center;
        height: 54px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.half{
      width: calc(50% - 40px);
      display: flex;
      flex-wrap: nowrap;
      padding: 40px 40px;
      text-align: left;
      @media (max-width: 900px) {
        width: 100%;
      }
      h3{
        font-size: 24px;
        color: #334958;
        margin-bottom: 20px;
      }
      p{
        font-size: 14px;
        margin-bottom: 20px;
        a{
          color: #00AF0D;
          text-decoration: underline;
          padding: 0;
          display: contents;
        }
      }
    }
    
    

    &.full{
      width: calc(100% - 40px);
      display: flex;
      flex-wrap: nowrap;
      padding: 40px 40px;
      justify-content: center;
      align-items: center;
      @media (max-width: 900px) {
        flex-direction: column;
        width: 100%;
      }
      h3{
        font-size: 24px;
        color: #334958;
      }
      p{
        font-size: 14px;
        color: #334958;
      }
      .left{
        width: 70%;
        text-align: left;
        @media (max-width: 900px) {
          width: 100%;
        }
      }
      .right{
        width: 30%;
        @media (max-width: 900px) {
          width: 100%;
          padding-top: 20px;
        }

      }
    }
  }

}

.member-dialog-txt {
  .inner {
    padding: 10px 30px 30px;
    font-size: 14px;
    color: #828282;
    line-height: 1.35;
    background-color: #fff;
    p {
      margin: 10px 0;
      color: #242525;
      i {
        font-size: 24px;
        margin-right: 7px;
        margin-left: 3px;
        transform: scaleX(-1);
      }
      &.order-id {
        font-weight: 700;
        font-size: 28px;
        color: #00AF0D;
        line-height: 1.35;
      }
      &.call-id {
        font-weight: 700;
        font-size: 28px;
        color: #334958;
        line-height: 1.35;
      }
    }
  }
}

.page-mid.member-mid {
  padding-bottom: 15px;
  .quote-frm-wrap {
    margin-top: 0;
  }
}

.member-details-wrap-top {
  display: flex;
  align-items: center;
  h2 {
    margin-right: 30px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    align-items: flex-start;
    span {
      display: block;
      text-align: center;
      padding: 20px 0 0;
      width: 100%;
    }
  }
  a.btn {
    border: 1px solid #334958;
    background: none;
    font-size: 15px;
    color: #334958;
    text-align: center;
    padding-top: 14.5px;
    padding-bottom: 14.5px;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 600px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: 450px) {
      font-size: 14px;
    }
  }
}

.member-details-wrap {
  display: flex;
  align-items: center;
  h2 {
    margin-right: 30px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    align-items: flex-start;
    span {
      display: block;
      text-align: center;
      padding: 20px 0 0;
      width: 100%;
    }
  }
  a.btn {
    border: 1px solid #334958;
    background: none;
    font-size: 15px;
    color: #334958;
    text-align: center;
    padding-top: 14.5px;
    padding-bottom: 14.5px;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 600px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: 450px) {
      font-size: 14px;
    }
  }
}

// View Offer Dialog
.member-dialog-offer {
  position: relative;
  background-color: #fff;
  top: -1px;
  .total {
    display: flex;
    .col {
      width: 50%;
      padding: 20px 30px;
      background-color: #438ec3;
      h5 {
        font-family: $standard-font-family;
        font-weight: 700;
        font-size: 14px;
        color: #ffffff;
      }
      h3 {
        font-family: $title-font-family;
        font-size: 36px;
        color: #ffffff;
        line-height: 1;
        margin: 0 0 10px;
      }
      h6 {
        opacity: 0.65;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
      }
      &:nth-child(even) {
        background-color: #4e9ed7;
        display: none;
        color: blue;
      }
    }
  }
  .top {
    padding: 20px 30px;
    .inner {
      display: flex;
      .profile-pic {
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 60px;
        height: 60px;
        overflow: hidden;
        img {
          opacity: 0;
        }
      }
      .profile-desc {
        width: calc(100% - 60px);
        padding: 0 20px;
        h4 {
          font-family: $standard-font-family;
          font-weight: 700;
          font-size: 17px;
          color: #334958;
        }
        ul {
          margin-top: 5px;
          li {
            box-shadow: none;
            width: auto;
            font-weight: 600;
            font-size: 12px;
            color: #b3b3b3;
            margin: 0;
            display: inline-block;
            border: 0;
            padding: 0;
            padding-left: 20px;
            border-left: 1px #dbdbdb solid;
            margin-left: 20px;
            line-height: 1;
            &:nth-child(1) {
              padding-left: 0;
              margin-left: 0;
              border-left: 0;
            }
            span {
              display: block;
              margin-bottom: 3px;
            }
            strong {
              font-weight: 600;
              display: block;
              font-size: 15px;
              color: #5a5a5a;
            }
          }
        }
      }
    }
  }
  .mid {
    padding: 15px 30px;
    border-bottom: 1px #d7d7d7 solid;
    ul.price_break_down_grid {
      margin: 0;
      border: 0;
      padding: 0;
      li {
        margin: 10px 0;
        border: 0;
        padding: 0;
        background: #f5f5f5;
        border: 1px solid #e8e9ec;
        font-weight: 600;
        font-size: 11px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        &:nth-child(1) {
          border: 1px solid #e8e9ec;
        }
        span {
          border-right: 1px #e8e9ec solid;
          padding: 10px 15px;
          width: 140px;
          margin-right: 15px;
        }
      }
    }
  }
  .btm {
    padding: 20px 30px;
    .inner {
      display: flex;
      justify-content: space-between;
      .btn {
        width: calc(50% - 15px);
      }
    }
  }
}

.product-details-actions {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  .action-wrap {
    flex-grow: 1;
    text-align: right;
    a.btn {
      padding: 10.5px 25px;
      width: 100%;
      max-width: 229px;
      margin-left: 10px;
      font-size: 14px;
      display: inline-block;
      &.delete-offer-btn {
        background: none;
        padding: 10.5px 0;
        width: fit-content;
      }
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;
    .action-wrap {
      margin-top: 15px;
      text-align: center;
      a.btn {
        margin-left: 0;
        max-width: calc(50% - 10px);
        &:nth-child(1) {
          margin-right: 15px;
        }
        @media (max-width: 400px) {
          display: block;
          max-width: none;
          &:nth-child(1) {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.phone_cta, .email_cta{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin:0 0 0 0 ;
  .navy a{
    color: #fff;
  }
}

.email_cta{
  margin:0 0 20px 0 ;
}

.addressarea{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0 0 20px 0 ;
  i{
    font-size: 30px;
    margin-bottom: 20px;
  }
  #address-id-txt{
    max-width: 250px;
    text-align: center;
    
  }
}
.btm_cta_area{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
    .btn_new{
      width: 100%;
      max-width: 300px;
      justify-content: center;
      align-items: center;
    }
    
}

.classified_form_outter{
  background-color: #fff;
  border-radius: 4px;
  padding: 30px;
  margin-bottom: 6rem;
  h3{
    margin-bottom: 3rem;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    text-transform:none;
    color: #1A1F23;
    display: block;
    margin-bottom: 5px;
  }

  input,
  textarea {
    background-color: #f5f5f5;
    box-shadow: none;
    margin-bottom: 30px;
    color: #3d3d3d;
    font-size: 14px;
    border-radius: 4px;
    &:placeholder {
      color: #3d3d3d;
    }
  }

  .form-wrap{
    background-color: #f5f5f5;
    box-shadow: none;
    padding: 16px 23px;
    margin-bottom: 30px;
    border-radius: 4px;
    select{
      background-color: rgba(255, 255, 255, 0);
      border: none;
      color: #3d3d3d;
      font-size: 14px;
      width: 100%;
    }
  }

  .pricingoption{
    display: flex;
    gap: 10px;
    .form-wrap {
      margin-bottom: 0;
    }
    input{
      margin:0;
    }
  }

  .halfandhalf{
    display: flex;
    gap: 10px;
    width: 100%;
    div{
      width: 50%;
    }
  }

  .suggested_category, .suggested_sub_category, .suggested_brand {
    background-color: #fffbe6;
    padding: 30px 30px 10px 30px;
    margin-bottom: 50px;
    border-radius: 4px;
    .mb-10{
      margin-bottom: 10px;
    }
    input{
      background-color: #fff;
    }
    
  }
}

.sub-cat-layout{
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  img{
    width: 20px;
    margin-right: 10px;
  }
  input, .form-wrap{
    width: calc(100% - 30px);
    margin:0;
  }

}


.imguploadcontainer{
  background-color: #f0f0f0;
  border-radius: 4px;
  display: flex !important;
  flex-wrap: wrap;
  padding: 20px;
  gap:20px;
  margin-bottom: 30px !important;
  width: 100%;
}
.fileinput{
 display: none;
}

#uploadimglabel{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.uploadmsg{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: max-content;
  padding: 30px ;
  svg{
    width: 60px;
  }
  p{
    font-size: 12px;
  }
}

.pip{
  width: calc(25% - 15px);
  margin-bottom: 20px;
  position: relative;
  img{
    pointer-events: none;
    aspect-ratio: 12 / 9;
    object-fit: cover;
    border-radius: 2px;
  }
  button{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 0;
    background: green;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}






