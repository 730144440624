.banner-item {
  padding: 35px 0;
  text-align: center;
  display: none;
  margin: 0 auto;
  width: 90%;
  &.showOnMobile {
    max-width: 320px;
    @media (max-width: 750px) {
      display: block;
    }
  }
  &.showOnDesktop {
    max-width: 750px;
    @media (min-width: 750px) {
      display: block;
    }
  }
  .caption-txt {
    margin: 0 auto;
    text-align: right;
    font-family: "montserrat", sans-serif;
    font-size: 12px;
    color: #bdbdbd;
    font-style: normal;
    font-weight: 600;
  }
}
