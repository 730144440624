.basket-wrap {
    padding:0;
    margin:20px auto;
    margin-top: 0;
    background: #fff;
    h1 {
        font-size: 28px;
        font-weight: 500;
        color: #334958;
        margin: 10px 0;
    }
    h3.zeroItems {
        margin:1.5em auto;
    }
    .column {
        margin:10px 0;
    }

    table {
        width:100%;
        border-collapse:separate; 
        border-spacing: 0 1em;
    
        tbody {
            tr {
                background: #FFFFFF;
               
                margin:10px 0;
                
                overflow: hidden;
                @media (max-width: 670px) {
                    display:flex;
                    flex-wrap: wrap;
                }
                td {
                    padding:15px 10px;
                    font-size: 12px;
                    color: #525252;
                    border-bottom:1px #ececec solid !important;
                    border-top: none !important;
                    
                    @media (max-width: 670px) {
                        padding:0 10px 15px 10px;
                        align-items: center;
                        justify-content: flex-start;
                        display: flex;
                    }
                    &:nth-child(1) {
                        padding:20px 20px 20px 30px;
                        width: 56%;
                        @media (max-width: 670px) {
                            width: 100%;
                            border-bottom:none !important;
                        }
                        
                    }
                    &:nth-child(2) {
                        @media (max-width: 670px) {
                            padding:0 20px 20px 30px;
                        }
                        
                    }
                    &:last-child {
                        padding:20px 30px 20px 20px;
                        width: 120px;
                        @media (max-width: 670px) {
                            flex-grow: 1;
                            align-items: center;
                            padding:0 30px 20px 20px;
                        }
                    }
                    h4 a{
                        font-family: $standard-font-family;
                        color: #1A1F23;
                        font-weight: 700;
                        font-style: normal;
                        font-size: 15px ;
                    }
                    .product-thumb{
                        width: 120px;
                        img{
                            width: 100%;
                        }
                    }
                    .deletebutton{
                        display: flex;
                    }

                }
            }
        }
    }

    .shopping-cart {
        .product-item {
            display: flex;
            align-items: center;
            .product-info {
                padding-left:15px;
            }
        }
        .form-control.cart-quantity {
            margin:0; 
            font-size:12px;
            width: 60px;
            display: inline;
            box-shadow: none;
            background-color: #E0E4E9;
            border: none;
            padding: 7px;
            border-radius: 4px;
        }
        .fa-fw {
            font-size:12px;
        }
        /* Qty */
        .form-inline {
            margin:0;
            input {
                vertical-align: top;
            } 
        }
        /* Delete Icon */
        a.remove-from-cart {
            .ladda-label{
                display: flex;
                flex-direction: row;
                justify-content: end;
                align-items: center;
                color: #525252;
                img{
                    margin-left: 10px;
                }
            }
           
        }
        thead th {
            padding:0;
            border:0;
        }
        table td {
            vertical-align: middle;
        }
    }
    /* footer */
    .shopping-cart-footer {
        display:flex;
        color:#334958;
        &.bold, &.bold h4, {
            font-weight: bold;
        }
        h4 {
            margin-right:10px;
        }
        div {
            flex-grow: 1;
            form {
                display: inline-block;
                margin:0;
                width: auto;
                border:0;
                button {
                    display: inline-block;
                    margin:0;
                    width:auto;
                    padding:0;
                    background: none;
                    border:0;
                    i {
                        color:#b32317;
                    }
                }
            }
        }
    }
    .btn-outline-secondary {
        color:#334958;
        font-size:12px;
        text-decoration: underline;

    }
}

.validation_msg {
    padding:10px 20px;
    margin-bottom:10px;
    font-weight: 500;
    font-size: 17px;
    color: #5a5a5a;
    display: block;
    background-color:#fbf6ca;
    width:100%;
}

#remove_del_btn {
    color: #334958;
    font-size: 12px;
    text-decoration: underline;
}


.shopping-cart-totals{
    padding:0 30px 30px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 880px) {
        flex-direction: column;
      }
    .leftcol{
        font-size: 16px;
    }
    .rightcol{
        min-width: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 16px;
        @media (max-width: 880px) {
            align-items: flex-start;
            margin-top: 20px;
          }
        h3 {
            font-size: 18px;
            line-height: 1.2;
            font-style: normal;
            font-weight: $medium;
            color: #1a1f23;
            font-family: $title-font;
            font-weight: 700;
            font-style: normal;
            text-align: left;
            margin-bottom: 10px;
        }
        form {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 10px;
        }
        select{
            background-color: #FFEBE8;
            border: 0;
            padding: 15px;
            border-radius: 6px;
            font-size: 16px;
            @media (max-width: 880px) {
                width:100%;
              }
        }
    }
}

.nextprevbuttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    .btn_new{
        width: fit-content;
    }
}

.delivery-selected{
    color: #525252;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    border-radius: 6px;
    background-color: #F4F6F6;
    width: 100%;
    form{
        width: fit-content;
        margin-bottom: none !important;
        .dele-dest{
            background: none;
            border: 0;
            color: #525252;
            font-size: 12px;
        }
    }
   
}

.bck_shopping{
    color: #525252;
}

