.video-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  height: 0;
  .play {
    /*
    display: block;
    background:rgba(red, 0.7);
    width:100%;
    height:100%;
    position: absolute;
    color:#fff;
    top:0;
    left:0;
    display:flex;
    justify-content:center;
    align-items:center;
    pointer-events: none;
    i {
      font-size:75px;
      color:#fff;
      text-shadow: 1px 2px rgba(0,0,0,0.17);
    }*/
  }
  &.paused-state {
    .play {
      display: flex;
    }
  }
  .video-container-inner {
    //position: relative;
    //padding-bottom: 200%;
    //transform: translateY(-35.95%);
  }
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

ul.accordian-mobile li ol.listVideos,
ol.listVideos {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  li {
    width: calc(25% - 20px);
    margin: 10px;
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    @media (max-width: 1100px) {
      width: calc(33% - 20px);
    }
    @media (max-width: 650px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 380px) {
      width: calc(1000% - 20px);
    }
    a {
      position: relative;
      display: block;
      .img-cover {
        height: 100%;
        width: 100%;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        z-index: 500;
        img {
          opacity: 0;
        }
      }
      .ico-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 1000;
        justify-content: center;
        align-items: center;
        span {
          display: block;
          img {
          }
        }
      }
    }
  }
}
