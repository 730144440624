h1,h2,h3, h4 {
  font-family:$title-font-family;
  font-style: normal;
  font-weight:$medium;
}

h1 {
  letter-spacing: 0;
  font-style: normal;
  font-size: 72px;
  line-height: 1.2;
  font-weight:$regular;
  @media (max-width:900px) { font-size:60px; }
  @media (max-width:600px) { font-size:48px; }
  @media (max-width:450px) { font-size:36px; }
}

h2 {
  font-size: 42px;
  line-height: 1.2;
  @media (max-width:900px) { font-size:24px; }
  @media (max-width:600px) { font-size:21px; }
  @media (max-width:450px) { font-size:18px; }
}

p{
  font-weight:$regular;
  color: #76797B;
  font-size: 16px;

}

.color-white{
  color: #fff;;
}

.color-text{
  color: #76797B;
}

.color-navy{
  color: #1A1F23;
}

.title-font{
  font-family:$title-font;
  color: #1A1F23;
  font-weight: 700;
  font-style: normal;
}

.body-font{
  font-family:$standard-font-family;
  color: #1A1F23;
  font-weight: 700;
  font-style: normal;
}

.font-lg{
  font-size: 4.5rem;
  @media (max-width: 600px) {
    font-size: 3.5rem;
  }
}

.font-md{
  font-size: 3.3rem;
}

.font-sm{
  font-size: 2.2rem;
}

.title_centre{
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 6rem;
  h2{
    margin-bottom: 1.5rem;
  }
  &.mt{
    margin-top: 6rem;
  }
}

.italic{
  font-style: italic;
}

.title_left{
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 6rem 0 6rem 0;
  h3{
    @extend .title-font;
    @extend .font-md;
    padding:0 0 0.5rem 0 ;
    margin-bottom: 1rem;
    color: #fff;
  }
}
