form {
  width: 100%;
  margin: 25px auto;
  button.btn {
    border:0;
    /*
    display: inline-block;
    border-radius: 0;
    padding: 12.5px 20px;
    line-height: 1;
    font-size: 13px;
    max-width: 202px;
    border-radius: 40px;*/
  }
}
label {
  font-size: 11px;
  font-weight: 800;
  text-transform: uppercase;
  color: #676767;
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  border-radius: 0;
  margin: 10px 0;
  border: none;
  padding: 18px 25px;
  letter-spacing: 0;
  line-height: 1.35;
  border: 0;
  border-radius: 4px;
  color: #3d3d3d;
  font-size: 12px;
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);

  &.required {
    border: 1px solid #8b0000;
  }
  &.required-border {
    border: 1px solid #8b0000;
  }

  &:placeholder {
    color: #7d7d7d;
  }
  &.form-control {
    border-radius: 0;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7d7d7d;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #7d7d7d;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #7d7d7d;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #7d7d7d;
}
textarea {
  min-height: 136px;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
}

button {
  width: 100%;
}

.dialog-item {
  button {
    margin-top: 10px;
  }
}

.validation-msg {
  text-align: left;
  background: #f7f5d6;
  border: 1px solid #dad9bd;
  padding: 20px;
  margin-bottom: 30px;
  max-width: 427px;
  margin-left: auto;
  margin-right: auto;
  h4 {
    font-weight: 700;
    font-size: 18px;
    color: #334958;
    margin: 0 0 5px;
    line-height: 1;
  }
  p {
    font-size: 13px;
    color: #334958;
  }
}

.select_style {
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  font-family: $standard-font-family;
  position: relative;

  width: 100%;
  border-radius: 0;
  margin: 10px 0;
  border: none;
  padding: 18px 25px;
  letter-spacing: 0;
  line-height: 1.35;
  border: 0;
  border-radius: 4px;
  color: #3d3d3d;
  font-size: 12px;
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);

  @media (max-width: 900px) {
    font-size: 15px;
    line-height: 1em;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1em;
  }
  @media (max-width: 450px) {
    font-size: 12px;
    line-height: 1em;
  }
  &:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    color: #334958;
    top: 14.5px;
    right: 15px;
    pointer-events: none;
    font-size: 30px;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
    option {
      padding-right: 45px;
    }
  }
}

.error-input,
div.error-input {
  border: 1px $red solid;
}
.error-msg {
  font-size: 14px;
  padding: 5px 0;
  display: none;
  color: $red;
  text-align: right;
}

.frm-stage-wrap {
  font-size: 10px;
  text-align: center;
  color: #c8c8c8;
  font-style: normal;
  font-weight: 700;
  max-width: 506px;
  margin: 0 auto 25px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border:0;
  &:before {
    content: " ";
    border: 1px #c8c8c8 solid;
    position: absolute;
    top: 15.5px;
    left: 30px;
    width: calc(100% - 60px);
  }
  div, li {
    display: inline-block;
    position: relative;
    z-index: 100;
    span.num-wrap {
      border: 2px #c8c8c8 solid;
      display: inline-block;
      border-radius: 50%;
      font-weight: $medium;
      span.num {
        display: block;
        background-color: #c8c8c8;
        width: 31px;
        height: 31px;
        color: #fff;
        margin: 0 auto;
        border-radius: 50%;
        line-height: 27px;
        font-size: 14px;
        font-weight: $medium;
        border: 2px #f6f7fa solid;
      }
    }
    a {
      display:block;
      color: #c8c8c8;
    }
    span.lbl {
      display: block;
      text-transform: lowercase;
      font-size: 11px;
      margin-top: 10px;
      font-weight: $medium;
      @media (max-width: 380px) {
        display: none;
      }
    }
    &.active,
    &.done {
      color: $green;
      a {
        color: $green;
      }
      span.num-wrap {
        border: 2px $green solid;
        span.num {
          background-color: $green;
        }
      }
    }
  }
}

.submit_offer_frm {
  padding:  40px;
  position: relative;
  @media (max-width: 600px) {
    padding: 10px 20px;
  }
  @media (max-width: 450px) {
    padding: 10px 15px;
  }
  .loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  label {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  textarea {
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
    width: 100%;
    height: 176px;
  }
  .extra-form-wrap {
    vertical-align: top;
    .extra-input-item {
      display: inline-block;
      width: calc(50% - 13px);
      label {
      }
      &:nth-child(odd) {
        margin-right: 20px;
      }
      .extra-input-item-included {
        background-color: #e4e4e4;
        padding: 14px;
        display: block;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.19);
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  .currency-input-wrap {
    display: flex;
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
    .symbol {
      width: 39px;
      height: 46px;
      background-color: #e4e4e4;
      color: #979797;
      font-weight: 700;
      font-size: 12px;
      color: #979797;
      text-align: center;
      width: 39px;
      line-height: 46px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      &.active {
        opacity: 1;
        z-index: 1000;
      }
    }
    &.required-outline {
      border: 1px solid #ca7474;
    }
    input {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      width: calc(100% - 39px);
      margin: 0;
      height: 46px;
      vertical-align: top;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding-left: 55px;
    }
  }
}

.input-toggle {
  margin-bottom: 10px;
  input {
    display: none;
  }
  .input-toggle-wrap {
    display: flex;
    margin-bottom: 10px;
    .input-toggle-lbl {
      width: 50%;
      background: #e4e4e4;
      font-weight: 600;
      font-size: 12px;
      color: #334958;
      text-align: center;
      height: 46px;
      line-height: 46px;
      cursor: pointer;
      &.active {
        background-color: #334958;
        color: #fff;
      }
    }
  }
}

.quote-frm-wrap form p.frm_note,
p.frm_note {
  font-size: 11px;
  color: #939393;
  display: block;
  text-align: left;
  margin: 10px auto;
  a {
    text-decoration: underline;
    color: #939393;
  }
}

.required {
  background: #ca7474;
  width: 9px;
  height: 9px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 5px;
  margin-right: 5px;
}

.required-footnote {
  text-align: left;
  font-size: 11px;
  color: #939393;
  line-height: 1.35;
  padding-top: 30px;
  max-width: $quoteformmaxwidth;
  margin: 0 auto;
}

.StripeElement {
  background-color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.toggle-preferences-wrap {
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  color: #334958;
  padding: 20px 25px;
  margin: 20px auto;
  max-width: 545px;
  .toggle-preferences-inner {
    display: flex;
    text-align: left;
    @media (max-width: 475px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    div {
      text-align: left;
      font-size: 13px;
      color: #939393;
      line-height: 1.5;
    }
    .toggle-preferences-lbl {
      padding-right: 50px;
      width: calc(100% - 103px);
      @media (max-width: 475px) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 10px;
      }
    }
    .toggle-preferences-btns {
      width: 103px;
      border: 1px solid #979797;
      border-radius: 3px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      height: 40px;
      @media (max-width: 475px) {
        margin: 0 auto;
      }
      .toggle-preferences-btn {
        height: 100%;
        width: calc(50%);
        font-size: 12px;
        font-weight: 600;
        color: #334958;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        &.checked {
          color: #ffffff;
          background: #4e9ed7;
        }
        &:active {
          top: 1px;
        }
        span {
          display: block;
        }
        @media (max-width: 475px) {
          padding: 10px;
        }
      }
    }
  }
}
