.messaging-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: 750px) {
    flex-direction: row;
  }
  .messaging-wrap-details {
    max-width: 773px;
    flex-grow: 1;
    @media (max-width: 750px) {
      max-width: none;
      width: 100%;
    }
    h2 {
      font-size: 25px;
      color: #334958;
      line-height: 1.35;
    }
    form {
      input.btn {
        width: auto;
        max-width: 170px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 35px;
      li {
        flex-direction: row;
        width: 100%;
        margin: 20px 0;
        background: #ffffff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
        position: relative;
        .top {
          font-size: 14px;
          color: #b7b7b7;
          line-height: 1.6;
          padding: 18px 25px 45px;
        }
        .btm {
          display: flex;
          align-items: center;
          padding: 18px 25px;
          border-top: 1px #e8e9ec solid;
          @media (max-width: 450px) {
            flex-wrap: wrap;
            flex-direction: row;
            text-align: center;
          }
          .member-information {
            flex-grow: 1;
            .img-wrap {
              display: inline-block;
              width: 42px;
              height: 42px;
              background: #ffffff;
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
              margin-right: 7px;
              padding: 4px;
            }
            @media (max-width: 450px) {
              width: 100%;
              .img-wrap {
                margin-right: 0;
              }
            }
          }
          h4 {
            display: inline-block;
            font-size: 11px;
            color: #334958;
            @media (max-width: 450px) {
              width: 100%;
            }
          }
        }
        &:after {
          transform: rotate(-315deg);
          background: #ffffff;
          content: " ";
          width: 25px;
          height: 25px;
          position: absolute;
          left: -10px;
          top: 20px;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
          z-index: 100;
        }
        &:before {
          background: #ffffff;
          content: " ";
          width: 20px;
          height: 50px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 200;
        }
        &[data-type="customer"] {
          .btm {
            flex-direction: row-reverse;
            .member-information {
              flex-grow: 0;
            }
            h4 {
              flex-grow: 1;
            }
          }
          &:after {
            left: auto;
            right: -10px;
          }
          &:before {
            left: auto;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }

  .messaging-product-panel {
    display: inline-block;
    width: 336px;
    margin-left: 80px;
    @media (max-width: 900px) {
      margin-left: 40px;
    }
    @media (max-width: 750px) {
      margin-left: 0;
      width: 100%;
      margin-bottom: 50px;
    }
    .messaging-product-panel-inner {
      background: #ffffff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
      .top {
        padding: 20px;
        border-bottom: 1px #e7e8eb solid;
        .img-wrap {
          margin-bottom: 10px;
        }
      }
      .customer {
        padding: 0 20px 20px;
        label {
          margin-top: 20px;
          font-size: 14px;
          color: #939393;
        }
        h5 {
          font-size: 18px;
          color: #334958;
        }
      }
      .dealer {
        display: flex;
        align-items: center;
        padding: 20px 20px;
        .img-wrap {
          background: #ffffff;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
          width: 80px;
          height: 80px;
          padding: 6px;
        }
        .txt-wrap {
          width: calc(100% - 80px);
          padding-left: 10px;
          h5 {
            font-size: 17px;
            color: #334958;
          }
          label {
            font-size: 12px;
            color: #b3b3b3;
          }
          div {
            font-size: 15px;
            color: #5a5a5a;
            font-weight: 700;
          }
        }
      }
      .offer {
        padding: 20px;
        background: #438ec3;
        label {
          color: white;
        }
        h5 {
          font-size: 36px;
          color: #ffffff;
        }
        h6 {
          opacity: 0.65;
          font-weight: 600;
          font-size: 12px;
          color: #ffffff;
        }
        &.savings {
          background: #4e9ed7;
        }
      }
      .btm {
        padding: 15px 20px;
        a.btn {
          display: block;
          margin: 8px 0;
        }
      }

      &.offer-id-wrap {
        margin: 30px 0;
        padding: 20px;
        label {
          font-weight: 600;
          font-size: 14px;
          color: #334958;
          display: block;
          margin-bottom: 5px;
        }
        p {
          font-weight: 600;
          font-size: 28px;
          color: #00AF0D;
        }
      }
    }
  }
}
