.category-header {
  padding: 40px 0 50px;
  margin-top: 50px;
  @media (max-width: 600px) {
    font-size: 9vw;
    padding: 10px 0 50px;
  }
  .inner-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 620px) {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .category_title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media (max-width: 620px) {
      width: 100%;
      padding-bottom: 30px;
    }
    h1 {
      font-size: 50px;
      line-height: 1.2;
      font-style: normal;
      font-weight: $medium;
      color: #1a1f23;
      font-family: $title-font;
      font-weight: 700;
      font-style: normal;
      text-align: left;
      @media (max-width: 960px) {
        font-size: 5vw;
      }
      @media (max-width: 600px) {
        font-size: 9vw;
      }
    }
    h2 {
      font-size: 16px;
      text-align: left;
      font-weight: 600;
      span {
        color: #00AF0D;
      }
    }
    p {
      margin: 20px 0 35px;
      text-align: left;
      max-width: 530px;
      color: #616161;
      font-weight: 500;
      font-size: 15px;
      line-height: 1.5;
      width: 70%;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }
  .filter_toggle{
    background-color: #e0e4e9;
    width: 100%;
    padding: 15px 20px;
    display: none;
    flex-direction: row;
    border-radius: 6px;
    overflow: hidden;
    align-items: center;
    font-weight: 600;
    color: #000;
    font-size: 19px;
    cursor: pointer;
    @media (max-width: 960px) {
      display: flex;
      
    }
    &:before {
      background-image: url("../img/ico_filter.svg");
      display: block;
      content: " ";
      background-size: 24px 24px;
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      width: 40px;
      margin-right:10px;
    }
    span{
      flex-grow: 1;
    }
    &:after{
      
      background-image: url("../img/ico_arrow_left.svg");
      background-color: #000;
      display: block;
      content: " ";
      background-size: 20px 20px;
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      border-radius:30px;
      overflow-x: hidden;
    }
    
  }
  .grid_toggle {
    background-color: #e0e4e9;
    width: 80px;
    height: 40px;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    overflow: hidden;

      display: none;
    
    .switch {
      width: 50%;
      &:first-child,
      &:last-child {
        &:after {
          display: block;
          content: " ";
          background-size: 20px 20px;
          background-position: center;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
        }
      }
      &:first-child {
        &:after {
          background-image: url("../img/ico_rows_active.svg");
        }
      }
      &:last-child {
        &:after {
          background-image: url("../img/ico_cols_active.svg");
        }
      }
      &.active {
        background-color: #00af0d;
        &:first-child {
          &:after {
            background-image: url("../img/ico_rows.svg");
          }
        }
        &:last-child {
          &:after {
            background-image: url("../img/ico_cols.svg");
          }
        }
      }
    }
  }
}

.product-wrapper {
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 960px) {
    flex-direction: column;
  }

  .product-sidebar {
    width: 30%;
    padding-right: 30px;
    padding-bottom: 160px;
    @media (max-width: 1200px) {
      width: 35%;
    }
    @media (max-width: 960px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
    }
    &.hide-from-mobile {
      @media (max-width: 960px) {
        display: none;
      }
    }
    .product-sidebar-inner {
      padding: 0 30px 0 0;
      @media (max-width: 960px) {
        padding: 0;
      }
      .products-filter {
        @media (max-width: 960px) {
          border-bottom: 2px #e6e7e9 solid;
          margin-bottom: 40px;
          padding-bottom: 20px;
        }
        form{
          @media (max-width: 960px) {
            display: flex;

          }
          .products-filter-col{
            @media (max-width: 960px) {
              width: calc(50% - 40px);
              margin: 0 20px 40px;
            }
            @media (max-width: 600px) {
              width: calc(100% - 40px);
            }
          }
          .filter-outter{
            width: 100%;
            margin-bottom: 20px;

            @media (max-width: 960px) {
              width: 100%;
            }

            ul{
              li{
                margin: 12px 0;
                color: #616161;
                font-size: 13px;
                display: flex;
                align-items: center;
                .icheckbox_flat-blue, .iradio_flat-blue {
                  display: inline-block;
                  *display: inline;
                  vertical-align: middle;
                  margin: 0 10px 0 0 ;
                  padding: 0;
                  width: 24px;
                  height: 24px;
                  background:white;
                  border-radius: 24px;
                  border: none;
                  cursor: pointer;
                  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
                  &:after {
                    display: none;
                    pointer-events: none;
                  }
                  &.checked {
                    background:$green;
                    &:after {
                      
                      background-image: url("../img/ico_tick.svg");
                      background-size: 16px 16px;
                      background-position: center;
                      background-repeat: no-repeat;
                      width: 16px;
                      height: 16px;
                      
                      display: inline-block;
                      position: absolute;
                      top: 2px;
                      left: 6px;
                      content: "";
                      color: #fff;
                      content: " ";
                    }
                  }
                  
                }
              }
            }
          }
        }
        .sidesearch{
          position: relative;
          form {
            width: 100%;
            position: relative;
            margin: 0 0 25px 0;
            input, textarea {
              box-shadow: none;
            }
            #trigger-search-submit{
              position: absolute;
              top: 23px;
              right: 14px;
            }
          }

        }
      }
      .category-filter {

        border-bottom: 2px #e6e7e9 solid;
        border-top: 2px #e6e7e9 solid;
        padding: 40px 0;
        margin-bottom: 30px;
        margin-top: 30px;
        @media (max-width: 960px) {
          border-top: none;
          padding:0 0 40px 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          margin: 0 -20px 40px;
        }
        h3{
          @media (max-width: 960px) {
            width: 100%;
            margin: 0 20px;
          }
        }
        .select_outter{
            position: relative;
            @media (max-width: 960px) {
              width: calc(50% - 40px);
              margin: 0 20px 40px;
            }
            @media (max-width: 600px) {
              width: calc(100% - 40px);
            }
            &:after {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f107";
                color: #1a1f23;
                position: absolute;
                bottom: 7px;
                right: 0;
                font-size: 23px;
                width: 33px;
                background-color: #fff;
                pointer-events: none;
            }
            label{

            }
        }
        .form-wrap{
          position: relative;
          width:100%;
        }
        select {
              /* styling */
            border: none;
            width: 100%;
            border-radius: 4px;
            margin: 0;      
            box-sizing: border-box;
            background-color:#fff; 
            display: block;
            padding: 15px 17px;
            font-size: 13px;
            color: #4c4c4c;
            cursor: pointer;
            position: relative;
            margin-bottom:20px ;
            -webkit-appearance:none;
            @media (max-width: 960px) {
              margin-bottom:0;
            }
        }
        .loading-search{
          position: absolute;
          top: 0;
          left: 14px;
          width: 80%;
          height: 44px;
          background-color: #fff;
          display: none;
          align-items: center;
          justify-content: flex-start;
          &.show{
              display: flex;
              z-index: 100;
          }
          img{
              width: 30px;
          }
      }

        
      }
      button{
        @media (max-width: 960px) {
          width: 100%;
          margin: 0 20px;
        }
      }
      h2 {
        font-size: 18px;
        color: #334958;
        line-height: 1.3;
        margin: 0;

        padding: 0 0 10px 0;
        font-weight: 400;
      }
      h3 {
        font-size: 20px;
        color: #1a1f23;
        line-height: 1.3;
        margin: 0;
        padding: 0 0 10px 0;
        font-weight: 800;
      }
    }
  }
  .product-main {
    width: 70%;
    @media (max-width: 1200px) {
      width: 65%;
    }
    @media (max-width: 960px) {
        width: 100%;
    }

    &.quote-frm-wrap {
      max-width: auto;
      margin: 0 auto;
      text-align: left;
      padding-bottom: 60px;
      form {
        margin: 0;
        padding: 0;
        .quote_btn_area {
          position: fixed;
          bottom: 0;
          width: 100%;
          left: 0;
          z-index: 10000;
          padding: 0;
          opacity: 0;
          transition: all 0.2s ease-in-out;
          box-shadow: inset 0px -79px 49px -40px rgba(0, 0, 0, 0.3);
          pointer-events: none;
          &.show {
            opacity: 1;
            pointer-events:visible;
          }
          .btn-wrap {
            margin: 0 auto;
            padding-top: 0;
            padding: 20px;
            border: 0;
            width: 100%;
            border-radius: 0;
            max-width: 100%;
            background-color: #fff;
            box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
            .btns {
              margin: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
          }
        }
      }
    }
    .product-grid-wrap {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
    }
    &.grid-four{
      width: 100%;
      max-width: 100%;

    }
  }
}

.product-grid {
  transition: all 0.1s ease-in-out;
  opacity: 1;
  &.temp {
    opacity: 0;
    display: none;
    .product-grid-third {
      opacity: 0;
      display: none;
    }
    .product-grid-full {
      opacity: 0;
      display: none;
    }
  }
}

.product-grid-third {
  display: flex;
  margin: 0 -20px;
  flex-wrap: wrap;
  opacity: 1;

  .product-grid-item {
    width: calc(33% - 40px);
    margin: 0 20px 40px;
    background: #ffffff;
    text-align: left;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    @media (max-width: 1200px) {
      width: calc(50% - 40px);
    }
    @media (max-width: 600px) {
      width: calc(100% - 40px);
    }
    &.grid-four{
      width: calc(25% - 40px);
      @media (max-width: 1200px) {
        width: calc(33% - 40px);
      }
      @media (max-width: 1000px) {
        width: calc(50% - 40px);
      }
      @media (max-width: 800px) {
        width: calc(100% - 40px);
      }
      
    }
    &:hover {
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .details-overview {
    display: none;
  }
  .product-card-new {
    background-color: #fff;
    padding: 20px;
    box-shadow: none;
    height: 100%;
    &.product-listing-panel {
      margin: 0;
      position: relative;
      display: flex;
      flex-direction: column;
    }
    &.selected {
      background-color: #f1fff2;
      border: 2px solid #00ae0c;
    }
    .featured {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .image_outter {
      width: 100%;
      height: 10vw;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-bottom: 10px;
      @media (max-width: 1200px) {
        height: 20vw;
      }
      @media (max-width: 960px) {
        height: 30vw;
      }
      @media (max-width: 600px) {
        height: 60vw;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 12vw;
        @media (max-width: 1200px) {
          height: 20vw;
        }
        @media (max-width: 960px) {
          height: 30vw;
        }
        @media (max-width: 600px) {
          height: 60vw;
        }
      }
      
    }
    .product-card-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 10px 0 0 0;
      h3 {
        color: #1a1f23;
        font-weight: 700;
        font-size: 17px;
      }
      .btn_wrap {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 20px;
        .view_details {
          
        }
        .select-product-item {
          cursor: pointer;
          display: flex;
          width: 100%;
          margin: 0;
          background-color: #1a1f23;
          border-radius: 4px;
          color: #fff;
          border: none;
          padding: 15px;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-size: 13px;
          margin-bottom: 15px;
          @media (max-width: 900px) {
            width: 100%;
            padding: 20px 24px;

          }
          &:after {
            margin-left: 10px;
            background-color: #fff;
            border-radius: 50%;
            display: block;
            content: " ";
            background-size: 12px 12px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../img/ico_tick.svg");
            height: 24px;
            width: 24px;
          }
          span {
            cursor: pointer;
          }
          .icheckbox_flat-blue {
            display: none;
          }
          .deselect {
            display: none;
            opacity: 0;
          }
        }
      }
    }

    &.selected {
      &:after {
        background: $green;
        background-image: url("../img/ico_tick.svg");
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: -15px;
        right: -15px;
        content: "";
        color: #fff;
        content: " ";
      }
      .btn_wrap {
        .btn_new {
          background-color: #fff;
        }
        .select-product-item {
          &:after {
            background-color: #00af0d;
          }
          .select {
            opacity: 0;
            display: none;
          }
          .deselect {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
}

 


.product-grid-full {
  display: flex;
  margin: 0 -20px;
  flex-wrap: wrap;
  opacity: 1;
  .product-grid-item {
    width: calc(33% - 40px);
    margin: 0 20px 40px;
    background: #ffffff;
    padding: 10px;
    text-align: left;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    @media (max-width: 1200px) {
      width: calc(50% - 40px);
    }
    @media (max-width: 600px) {
      width: calc(100% - 40px);
      margin: 0 20px 40px;
      padding: 20px;
    }
    &.grid-four{
      width: calc(25% - 40px);
      @media (max-width: 1200px) {
        width: calc(33% - 40px);
      }
      @media (max-width: 1000px) {
        width: calc(50% - 40px);
      }
      @media (max-width: 800px) {
        width: calc(100% - 40px);
      }
      
    }
    &:hover {
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .details-overview {
    display: block;
    display: flex;
    flex-direction: row;
    margin: 0 -10px;
    flex-wrap: wrap;
    padding: 10px;
    @media (max-width: 800px) {
      width: 100% ;
      margin: 0;
    }
    .spec-info {
      width: calc(50% - 20px);
      background: #ffffff;
      text-align: left;
      padding: 8px 0;
      margin: 0 10px;
      border-bottom: 1px solid #e6e7e9;
      @media (max-width: 960px) {
        width: calc(100% - 20px);
        padding: 12px 0;
      }
      h3 {
        font-size: 11px;
        color: #bfbfbf;
        font-weight: 400;
        padding-bottom: 3px;
        line-height: 1;
        @media (max-width: 960px) {
          font-size: 13px;
        }
      }
      h4 {
        font-size: 14px;
        color: #1a1f23;
        @media (max-width: 960px) {
          font-size: 16px;
        }
      }
    }
  }
  .product-card-new {
    background: #f4f6f6;
    box-shadow: none;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    @media (max-width: 600px) {
      padding: 20px;
    }
    &.product-listing-panel {
      margin: 0;
      position: relative;
      display: flex;
      flex-direction: column;
    }
    &.selected {
      background-color: #f1fff2;
      border: 2px solid #00ae0c;
    }
    .featured {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .image_outter {
      height: 14rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      @media (max-width: 600px) {
        height: 60vw;
      }
      img {
        object-fit: cover;
        height: 14rem;
        width: 100%;
        @media (max-width: 600px) {
          height: 60vw;
        }
      }
    }
    .product-card-body {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      flex-direction: column;
      padding: 20px 10px 0px 10px;
      width: 100%;
      @media (max-width: 600px) {
        padding: 30px 0 0 0;
      }
      .title_area {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        padding-bottom: 2rem;
        h3 {
          color: #1a1f23;
          font-weight: 700;
          font-size: 17px;
          width: 90%;

        }
        .product-brand-wrap {
          margin-bottom: 4px;
          
            width: 100%;
          
        }
      }

      .btn_wrap {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        
        .select-product-item {
          cursor: pointer;
          display: flex;
          width: 100%;
          margin: 0;
          background-color: #1a1f23;
          border-radius: 4px;
          color: #fff;
          border: none;
          padding: 10px;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-size: 13px;
          margin-bottom: 10px;
          @media (max-width: 900px) {
            width: 100%;
            
          }
          &:after {
            margin-left: 10px;
            background-color: #fff;
            border-radius: 50%;
            display: block;
            content: " ";
            background-size: 12px 12px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../img/ico_tick.svg");
            height: 24px;
            width: 24px;
          }
          span {
            cursor: pointer;
          }
          .icheckbox_flat-blue {
            display: none;
          }
          .deselect {
            display: none;
            opacity: 0;
          }
        }
      }
    }

    &.selected {
      &:after {
        background: $green;
        background-image: url("../img/ico_tick.svg");
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: -15px;
        left: -15px;
        content: "";
        color: #fff;
        content: " ";
      }
      .btn_wrap {
        .btn_new {
          background-color: #fff;
        }
        .select-product-item {
          &:after {
            background-color: #00af0d;
          }
          .select {
            opacity: 0;
            display: none;
          }
          .deselect {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
}

.product-card-new {
  background-attachment: #fff;
  padding: 20px;
  &.product-listing-panel {
    box-shadow: none;
    margin: 0;
    position: relative;
  }
  .image_outter {
    width: 100%;
    height: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 12vw;
    }
  }
}
